import { IShoppingCart, IOrder } from "../../Models/Model";
import { GetTotalPrice } from "../ProductsCalculation";
import { requestPrint } from "./bxlcommon";
import { checkPrinterStatus, cutPaper, getPosData, printText, setCharacterset, setInternationalCharset, setPosId } from "./bxlpos";

function PrintProductLines(orderLines: IShoppingCart[]) {
  orderLines.forEach((orderLine) => {
    let text = "";
    let leftSize = orderLine.product.name.length;
    let midSize = `${orderLine.quantity} ${orderLine.product.unit}`.length;
    let rightSize = `${orderLine.quantity * orderLine.product.price}`.length;
    let rest = 42 - leftSize - midSize - rightSize;

    let rightMargin = 10 - rightSize;
    text +=
      orderLine.product.name +
      " ".repeat(Math.ceil(rest - rightMargin)) +
      `${orderLine.quantity} ${orderLine.product.unit}` +
      " ".repeat(rightMargin) +
      `${orderLine.quantity * orderLine.product.price}`;
    printText(`${text}\n`, 0, 0, true, false, false, 0, 0);
    if (orderLine.mixMithaiBox) {
      printText(`${orderLine.product.description}\n`, 0, 0, false, false, false, 0, 0);
    }
  });
}

//TODO
//Set opp webPrintSdk og bruk riktig navn på printer
const printerName = "Printer1";

export function PrintOrder(order: IOrder) {
  setCharacterset(865);
  setPosId(issueID);
  checkPrinterStatus();

  printText("\n\nYadgaar sweets & bakers\n", 0, 0, false, false, false, 0, 1);
  printText("Tøyengata 27, 0578 Oslo\n", 0, 0, false, false, false, 0, 1);
  printText("Tel: 22 68 20 68\n", 0, 0, false, false, false, 0, 1);
  printText("Post@Yadgaar.no\n", 0, 0, false, false, false, 0, 1);
  printText("www.yadgaar.no\n", 0, 0, false, false, false, 0, 1);
  printText("------------------------------------------\n", 0, 0, false, false, false, 0, 0);
  printText(`Klikk og hent\n${order.customer.name} - ${order.customer.phoneNumber}\n\n`, 0, 1, false, false, false, 0, 1);
  printText(`${order.pickUpId}\n\n`, 4, 4, true, false, false, 0, 1);
  printText(`Kunde: ${order.customer.name} - ${order.customer.phoneNumber}\n`, 0, 0, false, false, false, 0, 0);
  printText(`Bestilt: ${order.time} ${order.date} \n`, 0, 0, false, false, false, 0, 0);
  if (order.message) {
    printText(`\nMelding fra kunde:\n`, 0, 0, true, false, false, 0, 0);
    printText(`\n${order.message}\n`, 0, 1, false, false, false, 0, 0);
  }
  printText("------------------------------------------\n\n", 0, 0, false, false, false, 0, 0);
  PrintProductLines(order.shoppingCart);
  printText("------------------------------------------\n", 0, 0, false, false, false, 0, 0);
  printText(`Total: ${GetTotalPrice(order.shoppingCart).toFixed(2)}\n`, 0, 1, true, false, false, 0, 2);
  printText("------------------------------------------\n\n", 0, 0, false, false, false, 0, 0);
  printText("Takk for besøket\nog velkommen igjen!", 0, 0, false, false, false, 0, 1);
  printText("\n\n\n\n\n", 0, 0, false, false, false, 0, 0);
  cutPaper(1);

  var strSubmit = getPosData();

  console.log(strSubmit);

  issueID++;
  requestPrint(printerName, strSubmit, (text: string) => console.log(text));

  return true;
}

var issueID = 0;
