import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Helpers/Loader";
import { NothingHere } from "../../Helpers/NothingHere";
import { getProductsByCategory } from "../../Logic/ProductService";
import { IProduct, IShoppingCart } from "../../Models/Model";
import { Breadcrumbs } from "../Navigation/Breadcrumbs";
import { ProductsGrid } from "../Product/ProductsGrid";
import { PickAndMixAdvertising } from "../PickAndMix/PickAndMixAdvertising";
import { useSelector } from "react-redux";

export function CategoryContent() {
  let { categoryName } = useParams();
  const isMithai = categoryName == "Mithai";
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("lg"));
  const category = categoryName?.replaceAll("-", " ");
  useEffect(() => {
    fetchData();
  }, [categoryName]);

  const shoppingCartItems = useSelector(
    (state: any) => state.ProductStore.shoppingCart as IShoppingCart[],
  );

  const fetchData = async () => {
    const response = (await getProductsByCategory(category)) as IProduct[];
    if (Array.isArray(response) && response.length !== 0) {
      setProducts(response);
    }
    setLoaded(true);
  };

  return (
    <>
      {!isLoaded && <Loader />}
      {isLoaded && category && products.length !== 0 && (
        <Container maxWidth="xl">
          <Breadcrumbs location={category as string} logList={[]} />
          <Typography variant={isSmallScreen ? "h4" : "h2"} gutterBottom paddingBottom={"0.5em"}>
            {category}
          </Typography>
          {isMithai && <PickAndMixAdvertising />}
          {products && (
            <ProductsGrid
              isFavoriteComponent={false}
              columns={{ xs: 2, sm: 2, md: 3, xl: 4 }}
              products={products}
              shoppingCartItems={shoppingCartItems}
            />
          )}
        </Container >
      )
      }

      {isLoaded && (!category || products.length === 0) && <NothingHere />}
    </>
  );
}
