export function handlePhoneNumberValidation(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>,
  setPhoneNumberValid: React.Dispatch<React.SetStateAction<boolean>>,
): void {
  setPhoneNumber(e.target.value);
  setPhoneNumberValid(isphoneNumberValid(e.target.value));
}

export function isphoneNumberValid(phoneNumber: string) {
  return /^((0047)?|(\+47)?)[4|9]\d{7}$/.test(phoneNumber);
}
