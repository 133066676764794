import { Button, Container, FormControl, Typography } from "@mui/material";
import { useState } from "react";
import TextFieldString from "../Inputs/TextFieldString";

import { Dropzone } from "./Dropzone";

export function CategoryForm() {
  const [name, setName] = useState("");
  const [images, setImages] = useState<any[]>([]);
  const [description, setDescription] = useState("");

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "3rem",
        paddingTop: "2rem",
      }}
    >
      <Typography variant="h3" component="div" gutterBottom align="center">
        Legg til en ny kategori
      </Typography>

      <FormControl>
        <TextFieldString
          label="Navn"
          value={name}
          setValue={setName}
          required={true}
        />
        <TextFieldString
          label="Beskrivelse"
          value={description}
          setValue={setDescription}
          required={true}
        />

        <Dropzone files={images} setFiles={setImages} />
        <Button variant="contained">Legg til</Button>
      </FormControl>
    </Container>
  );
}
