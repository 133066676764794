import { Badge, Box, Button, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  GetProductPrice,
  GetSalePercent,
} from "../../../Logic/ProductsCalculation";
import { IShoppingCart } from "../../../Models/Model";
import {
  setProductShoppingCart,
  removeProductShoppingCart,
  closeSideDrawer,
} from "../../../Store/ProductsStore";
import CloseIcon from "@mui/icons-material/Close";
import "./OrderLine.scss";
import { useNavigate } from "react-router-dom";
import { QuantityCounter } from "../../Inputs/QuantityCounter";

export interface IShoppingCartProps {
  shoppingCart: IShoppingCart;
  shouldHaveLine: boolean;
}

export function OrderLine(props: IShoppingCartProps) {
  const dispatch = useDispatch();
  const [quantity, setQuantityState] = useState(props.shoppingCart.quantity);
  const product = props.shoppingCart.product;
  const isMixMithaiBox = props.shoppingCart.mixMithaiBox;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      setProductShoppingCart({
        product: product,
        quantity: quantity,
        isMixMithaiBox: false,
      }),
    );
  }, [quantity]);

  const deleteProductFromOrder = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(
      removeProductShoppingCart({
        productId: props.shoppingCart.product.productId,
      }),
    );
  };

  const changeMixMithaiItems = () => {
    navigate("/Kategori/Mithai/PlukkMiks", {
      state: {
        productId: product.productId,
        description: product.description,
        quantity: quantity,
      },
    });
    dispatch(closeSideDrawer());
  };

  const removeItemFromMixMithai = (
    e: React.MouseEvent<HTMLButtonElement>,
    item: string,
  ) => {
    e.stopPropagation();

    const copyDescriptionList = [...product.description.split(",")];
    const newDescriptionList = copyDescriptionList.filter(
      (items) => items !== item,
    );

    if (newDescriptionList.length == 0) {
      deleteProductFromOrder(e);
    } else {
      //update product in shoppingCart
      dispatch(
        setProductShoppingCart({
          product: { ...product, description: newDescriptionList.join(",") },
          quantity: quantity,
          isMixMithaiBox: true,
        }),
      );
    }
  };

  return (
    <Box className="orderLine">
      <Box
        component="img"
        src={product.images[0] ? product.images[0].url : "/noImage.png"}
        sx={{
          width: { xs: "72px", sm: "99px" },
          height: { xs: "72px", sm: "99px" },
          objectFit: "cover",
          aspectRatio: "1/1",
          borderRadius: "50%",
          marginLeft: "1em",
        }}
      ></Box>

      <Box className="orderLineProductInfo">
        <Typography className="orderLineProductInfoName">
          {product.name}
        </Typography>
        <Typography className="orderLineProductInfoPrice">
          {product.isOnSale ? product.salePrice : product.price}
          kr /{product.unit}
        </Typography>

        {product.isOnSale && (
          <Typography className="orderLineProductInfoDiscount">
            ({GetSalePercent(product.price, product.salePrice)}% Rabatt)
          </Typography>
        )}

        <Button
          className="orderLineProductInfoDelteBtn"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            deleteProductFromOrder(e)
          }
        >
          Slett
        </Button>
      </Box>

      <Box className="orderLineQuantityCounter">
        <QuantityCounter
          quantity={props.shoppingCart.quantity}
          setValue={setQuantityState}
          unit={product.unit}
        />
      </Box>

      <Typography className="orderLineProductPrice">
        {GetProductPrice(product, props.shoppingCart.quantity)
          .toFixed(2)
          .replace(/[.,]00$/, "")}
        ,-
      </Typography>

      {isMixMithaiBox && (
        <Box className="orderLineMithaiBox">
          <Button
            className="orderLineMithaiBoxChangeBtn"
            onClick={(e) => {
              e.preventDefault();
              changeMixMithaiItems();
            }}
          >
            Endre valg
          </Button>
          <div className="orderLineMithaiBoxList">
            {product.description &&
              product.description.split(",").map((description, key) => (
                <Badge
                  key={key}
                  sx={{
                    cursor: "pointer",
                    "& .MuiBadge-badge": {
                      backgroundColor: "white ",
                      border: "1px solid #707070 ",
                      borderRadius: "50%" as "borderRadius",
                      width: "25px ",
                      height: "25px" as "height",
                    },
                  }}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    removeItemFromMixMithai(e, description)
                  }
                  badgeContent={<CloseIcon fontSize="small" color="error" />}
                >
                  <Typography className="orderLineMithaiBoxItems">
                    {description}
                  </Typography>
                </Badge>
              ))}
          </div>
        </Box>
      )}
      {props.shouldHaveLine && <Divider className="orderLineDivider" />}
    </Box>
  );
}
