import { Box, Typography } from "@mui/material";
import Mithai from "../../assets/AboutMithai.png";
import "./About.scss";

export function About() {
  return (
    <Box className="about">
      <Typography className="aboutTitle">
        Om Yadgaar Sweets &amp; Bakers AS
      </Typography>

      <Box className="aboutContentWrap">
        <Typography className="aboutContent">
          Yadgaar Sweets tilbyr pakistanske søtsaker i Oslo. Vi har oppnådd et
          vedvarende nivå av ytelse i matindustrien gjennom årene. Yadgaar Sweets
          er rangert som den ledende produsenten og distributøren av asiatiske
          søtsaker i Norge. Vi er stolte av å kunne tilby våre kunder de
          ferskeste og mest smakfulle søtsakene hver dag.
        </Typography>

        <Typography className="aboutContent">
          Vi er stolte av å tilby våre kunder de beste og mest unike søtsakene,
          og vi jobber hardt for å sikre at du alltid finner noe som passer deg.
          Vårt team av erfarne konditorer og bakere lager alle våre produkter
          fra bunnen av.
        </Typography>
        <Typography className="aboutContent">
          Hos oss vil du finne alt fra klassiske favoritter til spennende og
          innovative smaker som vil gi deg en smaksopplevelse du sent vil
          glemme. Vi er fast bestemt på å tilby deg den beste opplevelsen hver
          gang du besøker oss, og vi håper du vil nyte vår ferske og delikate
          utvalg av søtsaker.
        </Typography>
      </Box>

      <Box
        component="img"
        sx={{
          height: { xs: "100%", md: "50%" },
          width: { xs: "100%", md: "50%" },
        }}
        alt="Mithai"
        src={Mithai}
      />
    </Box>
  );
}
