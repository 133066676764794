const curDate = new Date();
const curTime = parseInt(
  curDate.getHours() + "" + ("0" + curDate.getMinutes()).substr(-2)
);
const day = curDate.getDay(); //Sunday = 0, Monday = 1, ...

const mondayToSaturday = (curTime: number) => {
  return curTime >= 1100 && curTime <= 2045 && day > 0;
};

const sunday = (curTime: number) => {
  return curTime >= 1300 && curTime <= 2045 && day == 0;
};

export const storeIsOpen = () => {
  return mondayToSaturday(curTime) || sunday(curTime);
};

export const storeIsClosing = () => {
  return curTime >= 2015 && curTime < 2045;
};
