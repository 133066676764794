import { Box, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Units } from "../../Models/Enum";
import { CustomButton } from "../../styles/CustomButton";

export interface IQuanityCounterProps {
  quantity: number;
  setValue: (value: number) => void;
  unit: Units | string;
}

export const QuantityCounter = (props: IQuanityCounterProps) => {
  const { quantity, unit, setValue } = props;

  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));

  function getIncrementValue() {
    switch (unit) {
      case Units.kg:
        return 0.5;
      default:
        return 1;
    }
  }

  const increaseValue = getIncrementValue();
  const setWeight = (e: React.MouseEvent<HTMLButtonElement>, value: number) => {
    e.preventDefault();
    e.stopPropagation();
    setValue(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        alignSelf: "center",
        width: "100%",
        maxWidth: "10rem",
        justifyContent: "space-between",
      }}
    >
      <CustomButton
        sx={{
          paddingTop: "5px",
          paddingBottom: "5px",
          border: "none",
          borderRadius: "6px",
          backgroundColor: "#555555",
          width: "fit-content",
        }}
        disabled={quantity < increaseValue}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          setWeight(e, quantity - increaseValue)
        }
      >
        <RemoveIcon
          fontSize={isDesktop ? "medium" : "small"}
          sx={{ verticalAlign: "middle", color: "white" }}
        ></RemoveIcon>
      </CustomButton>
      <span className="quantityCounterValue">
        {quantity} {unit}
      </span>
      <CustomButton
        sx={{
          paddingTop: "5px",
          paddingBottom: "5px",
          border: "none",
          borderRadius: "6px",
          backgroundColor: "#555555",
          width: "fit-content",
        }}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          setWeight(e, quantity + increaseValue)
        }
      >
        <AddIcon
          fontSize={isDesktop ? "medium" : "small"}
          sx={{ verticalAlign: "middle", color: "white" }}
        ></AddIcon>
      </CustomButton>
    </Box>
  );
};
