import {
  Button,
  Checkbox,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getProducts, putProduct } from "../../../Logic/ProductService";
import { IProduct } from "../../../Models/Model";
import "./ProductTable.css";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Loader from "../../../Helpers/Loader";
import { NothingHere } from "../../../Helpers/NothingHere";

export function ProductTable() {
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const [isLoaded, setLoaded] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const products = (await getProducts()) as IProduct[];

    if (Array.isArray(products) && products.length !== 0) {
      const sortedProducts = products.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      setAllProducts(sortedProducts);
    }
    setLoaded(true);
  };

  const setClickFavorite = async (product: IProduct, index: number) => {
    setLoaded(false);
    product.isBestSeller = !product.isBestSeller;
    await updateStateAndPutProduct(product, index);
    setLoaded(true);
  };

  const setClickSoldOut = async (product: IProduct, index: number) => {
    product.isSoldOut = !product.isSoldOut;
    await updateStateAndPutProduct(product, index);
  };

  async function updateStateAndPutProduct(product: IProduct, index: number) {
    setLoaded(false);
    await putProduct(product);
    const newAllProducts = [...allProducts];
    newAllProducts[index] = product;
    setAllProducts(newAllProducts);
    setLoaded(true);
  }

  return (
    <>
      {!isLoaded && <Loader />}

      {isLoaded && allProducts.length === 0 && <NothingHere />}

      {isLoaded && allProducts.length !== 0 && (
        <Container
          maxWidth="xl"
          sx={{ marginBottom: "3rem" }}
          id="productTable"
        >
          <h2 id="productTitle">Liste med produkter</h2>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 750 }}
              size={"medium"}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Navn</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Pris</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Kategori</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Enhet</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Utsolgt</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Favoritt</b>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allProducts &&
                  allProducts.map((product: IProduct, i: number) => (
                    <TableRow key={i}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell align="right">
                        {product.isOnSale ? product.salePrice : product.price}
                      </TableCell>
                      <TableCell align="right">
                        {product.categoryName}
                      </TableCell>
                      <TableCell align="right">{product.unit}</TableCell>
                      <TableCell align="right">
                        <Checkbox
                          checked={product.isSoldOut}
                          onClick={() => setClickSoldOut(product, i)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => setClickFavorite(product, i)}>
                          {product.isBestSeller ? (
                            <StarIcon />
                          ) : (
                            <StarBorderIcon />
                          )}
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button>
                          <EditIcon
                            onClick={() =>
                              navigate("/Admin/NyttProdukt", { state: product })
                            }
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
}
