import { IOrder, IShoppingCart } from "../../../Models/Model";
import {
  Button,
  Divider,
  Typography,
  IconButton,
  useTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogContent,
  TextField,
} from "@mui/material";
import { GetTotalPrice } from "../../../Logic/ProductsCalculation";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box } from "@mui/system";
import { editOrder } from "../../../Logic/ProductService";
import { OrderStatus } from "../../../Models/Enum";
import CancelIcon from "@mui/icons-material/Cancel";
import { blue } from "@mui/material/colors";
import ForwardIcon from "@mui/icons-material/Forward";
import { sendSMSToCustomer } from "../../../Logic/CustomerService";
import { useState } from "react";
import { PrintOrder } from "../../../Logic/Printer/PrintService";

interface IOrderProps {
  order: IOrder;
  updateOrders(order: IOrder): void;
  dialog: boolean;
}

export function Order(props: IOrderProps) {
  const theme = useTheme();
  const { order, updateOrders, dialog } = props;
  const [SMSDialog, setSMSDialog] = useState(false);
  const [SMS, setSMS] = useState("");
  const defaultSMS = [
    "Hei. Denne varen er utsolgt. Du kan ikke svare på denne meldingen. Vennligst kontakt Yadgaar på 22 68 20 68. Hilsen Yadgaar Sweets",
    "Hei. Vi stenger snart, kan du komme og hente varen. Du kan ikke svare på denne meldingen. Vennligst kontakt Yadgaar på 22 68 20 68. Hilsen Yadgaar Sweets",
  ];

  const changeOrderStatus = async (status: OrderStatus) => {
    const editedOrder = { ...order };
    editedOrder.status = status;

    var isOrderUpdated = await editOrder(editedOrder);

    if (isOrderUpdated) {
      updateOrders(editedOrder);
    }
    return isOrderUpdated;
  };

  const SMSToCustomer = async (message: string) => {
    setSMSDialog(false);
    const orderWithMessage = { ...order };

    if (message.trim().length !== 0) {
      orderWithMessage.message = message;
      await sendSMSToCustomer(orderWithMessage);
    }
    setSMS("");
  };

  const finishOrder = async () => {
    var orderIsFinished = await changeOrderStatus(OrderStatus.Ferdig);
    if (orderIsFinished) {
      PrintOrder(order);
    }
  }

  const handleChangeSMSText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSMS(event.target.value);
  };

  const handleOpenSMSDialog = () => {
    setSMSDialog(true);
  };

  const handleCloseSMSDialog = () => {
    setSMSDialog(false);
  };

  const totalPrice = GetTotalPrice(order.shoppingCart);

  return (
    <Box
      key={order.orderId}
      boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;"
      borderRadius=".5rem"
      id={order.orderId}
      border={"1px solid #C0F3CC "}
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        margin=".6rem"
      >
        <Typography variant="body1" gutterBottom>
          <b>{order.pickUpId}</b>
        </Typography>
        <Typography variant="body1" gutterBottom textAlign="center">
          {order.customer.name} {order.customer.phoneNumber}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>{order.time}</b>
        </Typography>
      </Box>
      <Divider />

      {order.shoppingCart.map((productOrder: IShoppingCart, i: number) => (
        <Box
          padding=".4rem"
          sx={{ backgroundColor: i % 2 === 0 ? "#f5f5f5" : "white" }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="body1">{productOrder.product.name}</Typography>
            <Typography variant="body1">
              {productOrder.quantity} {productOrder.product.unit}
            </Typography>
          </Box>
          {productOrder.mixMithaiBox && (
            <Typography variant="caption">
              {productOrder.product.description}
            </Typography>
          )}
        </Box>
      ))}
      <Divider />
      {order.message && order.message !== "" && (
        <Box
          padding=".3rem"
          margin=".6rem"
          border={"1px solid " + theme.palette.primary.main}
        >
          <Typography variant="body1" gutterBottom>
            Melding fra kunde:
          </Typography>
          <Typography variant="body2">{order.message}</Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography
          variant="body2"
          sx={{ marginRight: "auto", marginLeft: ".6rem" }}
        >
          <b> TOTALT: {totalPrice}</b>
        </Typography>
        <IconButton color="info" sx={{ mr: "1rem" }} onClick={handleOpenSMSDialog}>
          <TextsmsOutlinedIcon />
        </IconButton>
        {dialog ? (
          <Button
            size="large"
            variant="text"
            color="error"
            startIcon={<CancelIcon />}
            onClick={() => changeOrderStatus(OrderStatus.Nytt)}
          >
            Angre
          </Button>
        ) : (
          <Button
            size="large"
            variant="text"
            color="success"
            startIcon={<CheckCircleIcon />}
            onClick={() => {
              finishOrder()
            }}
          >
            Fullfør
          </Button>
        )}
      </Box>

      {SMSDialog && (
        <Dialog open={SMSDialog} onClose={handleCloseSMSDialog}>
          <DialogTitle>
            <b> Velg melding eller skriv en selv </b>
          </DialogTitle>
          <List>
            {defaultSMS.map((sms) => (
              <ListItem button onClick={() => SMSToCustomer(sms)}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <ForwardIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={sms} />
              </ListItem>
            ))}
          </List>
          <DialogContent>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="filled"
              onChange={handleChangeSMSText}
              placeholder="Skriv en melding til kunden"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => SMSToCustomer(SMS)}>
              Send
            </Button>
            <Button
              variant="contained"
              color="inherit"
              onClick={handleCloseSMSDialog}
            >
              Avbryt
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
