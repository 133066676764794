import {
  Box,
  Button,
  Container,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SendOrderConfirmation } from "../../../Logic/CustomerService";
import { IOrder } from "../../../Models/Model";
import { emptyShoppingCart } from "../../../Store/ProductsStore";
import ScheduleIcon from "@mui/icons-material/Schedule";
import "./Stepper.scss";

export interface IOrderProps {
  order: IOrder;
}

export function SuccessfulCheckOut(props: IOrderProps) {
  const dispatch = useDispatch();
  const [confirmationSent, setconfirmationSent] = useState(false);
  useEffect(() => {
    dispatch(emptyShoppingCart());
    window.scrollTo(0, 0);
  }, []);

  const { order } = props;

  return (
    <Box>
      <Container
        sx={{
          textAlign: "center",
        }}
      >
        <Box>
          <Typography className="successOrderNr">Hentenummer</Typography>
          <Typography className="successPickUpId">{order.pickUpId}</Typography>
          <Typography className="successOrderName">
            {order.customer.name}, du kan hente din ordre om
          </Typography>
          <Box className="successBox">
            <ScheduleIcon sx={{ fontSize: { xs: "20px", lg: "40px" } }} />
            <Typography className="successOrderTime">15 minutter</Typography>
          </Box>

          <Button
            variant="outlined"
            onClick={async () => {
              await SendOrderConfirmation(order);
              setconfirmationSent(true);
            }}
            disabled={confirmationSent}
            sx={{ marginTop: "3em" }}
          >
            {confirmationSent
              ? "Ordrebekreftelse sendt på SMS"
              : "Send ordrebekreftelse på SMS"}
          </Button>
          {!confirmationSent && (
            <FormHelperText sx={{ textAlign: "center" }}>
              Klikk her for å motta sms om din ordre
            </FormHelperText>
          )}
          <Typography className="successThanksMessage">
            Takk for at du har handlet hos Yadgaar Sweets
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
