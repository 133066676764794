import { InputAdornment, TextField } from "@mui/material";

interface TextFieldNumberInterface {
  label: string;
  value: number;
  setValue: any;
  helperText: string;
  unit: string;
  required: boolean;
  disabled: boolean;
}

export default function TextFieldNumber(props: TextFieldNumberInterface) {
  return (
    <TextField
      label={props.label}
      variant="outlined"
      size="small"
      margin="normal"
      value={props.value}
      required={props.required}
      disabled={props.disabled}
      onWheel={event => { event.preventDefault(); }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        props.setValue(Number.parseInt(event.target.value))
      }
      helperText={props.helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{props.unit}</InputAdornment>
        ),
      }}
    />
  );
}
