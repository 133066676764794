import { Container, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CustomButton } from "../../styles/CustomButton";
import "./PickAndMixAdvertising.scss";

export function PickAndMixAdvertising() {
  const navigate = useNavigate();

  return (
    <Container
      onClick={() => navigate("/Kategori/Mithai/PlukkMiks")}
      maxWidth="xl"
      className="advertising__Wrappper"
    >
      <Box className="advertising_InnerWrapper">
        <Box className="advertising__LeftWrapper">
          <Box className="advertising__LeftInnerWrapper">
            <Typography className="advertising__LeftHeader">
              Lag din egen <br /> Mithai eske
            </Typography>
            <Typography className="advertising__LeftContent">
              Legg opp til fem forskjellige <br /> søtsaker i en eske
            </Typography>
            <CustomButton className="advertising__button">
              <Typography className="advertising_buttonText">
                Prøv nå
              </Typography>
              <ArrowForwardIcon
                sx={{ color: "black", fontSize: { xs: "1.5rem", lg: "3rem" } }}
              />
            </CustomButton>

            <figcaption>
              <i className="advertising__photo__credit">
                Foto: Shutterstock / NTB
              </i>
            </figcaption>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
