import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { NavigateFunction } from "react-router-dom";
import store from "..";
import { LogInDetails } from "../Models/Model";
import { setStatus } from "../Store/AdminStore";
import UserPool from "./UserPool";

export const getAuthDetails = (logInDetails: LogInDetails) => {
  return new AuthenticationDetails({
    Username: logInDetails.username,
    Password: logInDetails.password,
  });
};

export const authorizeUser = async (
  logInDetails: LogInDetails,
  navigate: NavigateFunction,
) => {
  return await new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: logInDetails.username,
      Pool: UserPool,
    });

    const authDetails = getAuthDetails(logInDetails);

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("Logger inn");
        store.dispatch(setStatus(true));
        navigate("/Admin");
        resolve(data);
      },
      onFailure: (err) => {
        console.error("onFailure: ", err);
        reject(err);
      },
      newPasswordRequired: (data) => {
        console.log("newPasswordRequired: ", data);
        resolve(data);
      },
    });
  });
};
