import ReactDOM from "react-dom";
import App from "./App";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import ProductStore from "./Store/ProductsStore";
import { BrowserRouter } from "react-router-dom";
import AdminStore from "./Store/AdminStore";
import CategoryStore from "./Store/CategoryStore";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { StrictMode } from "react";
import "./index.scss";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  ProductStore,
  CategoryStore,
  AdminStore,
});
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
const persistor = persistStore(store);

export default store;

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById("root"),
);

// If you want youhr app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about seresrewrvice workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
