import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";

export function XlContainerLayout() {
  return (
    <Container maxWidth="xl" disableGutters sx={{ minHeight: "100vh" }}>
      <Outlet />
    </Container>
  );
}
