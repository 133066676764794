import {
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Container,
} from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Helpers/Loader";
import { deleteProduct, putProduct } from "../../Logic/ProductService";

import { Units } from "../../Models/Enum";
import { IAttribute, ICategory, IProduct } from "../../Models/Model";
import {
  IProductAttribute,
  ProductAttribute,
} from "../Inputs/ProductAttribute";
import SelectBox from "../Inputs/SelectBox";
import TextFieldNumber from "../Inputs/TextFieldNumber";
import { TextFieldPrice } from "../Inputs/TextFieldPrice";
import TextFieldString from "../Inputs/TextFieldString";
import { Dropzone } from "./Dropzone";
import { Guid } from "guid-typescript";

export function ProductForm() {
  const state = useLocation();
  const [edit, setEdit] = useState(false);
  const [productPushRequest, setProductPushRequest] = useState<boolean>(false);
  const product = state.state as IProduct;
  const navigate = useNavigate();

  useEffect(() => {
    if (state.state !== null) {
      setEdit(true);

      setCategory(product.categoryName);
      setProductId(product.productId);

      setName(product.name);
      setDescription(product.description);
      loadExistingImages();

      setPrice(product.price);
      setUnit(product.unit);
      setIsOnSale(product.isOnSale);
      setPriceAfterSale(product.salePrice);
      setIsBestSeller(product.isBestSeller);
      setIsSoldOut(product.isSoldOut);

      setAttributes(getMappedProductAttributes(product.attributes));
      setSortIndex(product.sortIndex);
    }
  }, []);

  const loadExistingImages = async () => {
    if (product.images.length > 0) {
      const images = [];
      for (const image of product.images) {
        images.push({
          url: image.url,
          isUploaded: true,
        });
      }
      setImages(images);
    }
  };

  const categories = useSelector(
    (state: any) => state.CategoryStore.categories,
  ) as ICategory[];

  const [category, setCategory] = useState("");
  const [productId, setProductId] = useState("");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState<any[]>([]);

  const [price, setPrice] = useState(100);
  const [unit, setUnit] = useState("");
  const [isOnSale, setIsOnSale] = useState(false);
  const [priceAfterSale, setPriceAfterSale] = useState<number>(0);
  const [isBestSeller, setIsBestSeller] = useState(false);
  const [isSoldOut, setIsSoldOut] = useState<boolean>(false);

  const [attributes, setAttributes] = useState<IProductAttribute[]>([
    {
      id: Guid.create().toString(),
      key: "",
      value: "",
    },
  ]);
  const [sortIndex, setSortIndex] = useState(0);

  const putObject = async () => {
    setProductPushRequest(true);
    const body = {
      categoryName: category,
      productId: productId,

      name: name,
      description: description,
      images: images,

      price: price,
      unit: unit,
      isOnSale: isOnSale,
      salePrice: isOnSale ? priceAfterSale : 0,
      isBestSeller: isBestSeller,
      isSoldOut: isSoldOut,

      attributes: getMappedAttributes(attributes),
      sortIndex: sortIndex,
      oldProductName:
        product != null && product.name !== name ? product.name : "",
      oldCategoryName:
        product != null && product.categoryName !== category
          ? product.categoryName
          : "",
    };
    console.log(body);
    await putProduct(body);
    setProductPushRequest(false);
    navigate("/Admin/Produkter");
  };

  const getMappedAttributes = (
    attributesArray: IProductAttribute[],
  ): IAttribute[] => {
    const attributes = attributesArray
      .filter((a) => a.key != "" || a.value != "")
      .map((attribute: IProductAttribute) => {
        return {
          key: attribute.key,
          value: attribute.value,
        };
      });

    return attributes;
  };

  const getMappedProductAttributes = (
    attributesArray: IAttribute[],
  ): IProductAttribute[] => {
    if (attributesArray.length > 0) {
      return attributesArray.map((attribute: IAttribute) => {
        return {
          id: Guid.create().toString(),
          key: attribute.key,
          value: attribute.value,
        };
      });
    }

    return [
      {
        id: Guid.create().toString(),
        key: "",
        value: "",
      },
    ];
  };

  return (
    <>
      {productPushRequest && <Loader />}

      {!productPushRequest && (
        <Container
          maxWidth="md"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "3rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1rem",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography variant="h6" component="div">
              {edit ? `Endre ${name}` : "Legg til nytt produkt"}
            </Typography>
            <Typography variant="body2" component="div">
              {"  "} * må fylles ut!
            </Typography>
          </div>
          <TextFieldString
            label="Navn"
            value={name}
            setValue={setName}
            required={true}
          />

          <SelectBox
            label="Kategori"
            currentValue={category}
            helperText=""
            values={categories.map((categories) => categories.name)}
            required={true}
            setValue={setCategory}
          />
          <SelectBox
            label="Enhet"
            currentValue={unit}
            helperText=""
            values={Object.keys(Units) as Array<keyof typeof Units>}
            required={false}
            setValue={setUnit}
          />

          <TextField
            label="Beskrivelse"
            margin="normal"
            value={description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setDescription(event.target.value)
            }
            multiline
            rows={4}
          />

          <TextFieldNumber
            label="Rekkefølge"
            value={sortIndex}
            setValue={setSortIndex}
            required={true}
            helperText=""
            unit=""
            disabled={false}
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSoldOut}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIsSoldOut(event.target.checked);
                  }}
                />
              }
              label="Utsolgt"
            />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isBestSeller}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIsBestSeller(event.target.checked);
                  }}
                />
              }
              label="Bestselger"
            />
          </FormGroup>

          <TextFieldPrice
            price={price}
            setPrice={setPrice}
            isOnSale={isOnSale}
            setIsOnSale={setIsOnSale}
            priceAfterSale={priceAfterSale}
            setPriceAfterSale={setPriceAfterSale}
          />
          <ProductAttribute attributes={attributes} setValue={setAttributes} />

          <Dropzone setFiles={setImages} files={images} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Button variant="contained" onClick={putObject}>
              Last opp
            </Button>
            {edit && (
              <Button
                variant="outlined"
                color="error"
                onClick={async () => await deleteProduct(category, name)}
              >
                Slett
              </Button>
            )}
          </div>
        </Container>
      )}
    </>
  );
}
