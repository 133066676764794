import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "AdminStore",
  initialState: {
    status: false,
    session: undefined,
  },
  reducers: {
    setStatus(state: any, action: PayloadAction<boolean>) {
      state.status = action.payload;
    },
  },
});

export const { setStatus } = authSlice.actions;

export default authSlice.reducer;
