import { Box, Grid, Typography } from "@mui/material";
import "./ShopClosedComponent.scss";

export default function ShopClosedComponent() {
  return (
    <Box sx={{ textAlign: "center", marginTop: "2em" }}>
      <Grid container className="shopClosed">
        <Grid item>
          <Typography className="shopClosedHeader" component="div" gutterBottom>
            Nettbutikken er dessverre stengt
          </Typography>
          <Typography className="shopClosedSubTitle" component="div">
            Åpningstider for nettbutikken <hr />
          </Typography>
          <Typography className="shopClosedContent" component="div">
            Mandag - Lørdag:
          </Typography>
          <Typography
            className="shopClosedContent"
            component="div"
            gutterBottom
          >
            11:00 - 20:45
            <hr />
          </Typography>
          <Typography className="shopClosedContent" component="div">
            Søndag:
          </Typography>
          <Typography
            className="shopClosedContent"
            component="div"
            gutterBottom
          >
            13:00 - 20:45
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
