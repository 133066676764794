import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { OrderStatus } from "../../../Models/Enum";
import { Order } from "./Order";
import { IOrder } from "../../../Models/Model";
import React from "react";

interface IOrderComponentProps {
  order: IOrder;
  updateOrders: (order: IOrder) => void;
}

export const OrderComponent = (props: IOrderComponentProps) => {
  const { order, updateOrders } = props;

  const [dialogStatus, setDialogStatus] = React.useState(false);

  const handleOpenDialog = () => {
    setDialogStatus(true);
  };

  const handleCloseDialog = () => {
    setDialogStatus(false);
  };

  const updateOrder = (order: IOrder) => {
    updateOrders(order);
    handleCloseDialog();
  };

  return (
    <>
      <Typography variant="body1" key={order.pickUpId}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={handleOpenDialog}
        >
          {order.pickUpId}
        </Button>
      </Typography>
      {order.status == OrderStatus.Ferdig && dialogStatus && (
        <Dialog
          open={dialogStatus}
          onClose={handleCloseDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Fullført ordre</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {order && (
                <Order
                  updateOrders={updateOrder}
                  order={order}
                  dialog={true}
                ></Order>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Lukk</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
