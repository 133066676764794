import { Box, Button } from "@mui/material";
import { useMatch } from "react-router-dom";
import { LogOut } from "../../Logic/AccountService";

export function AdminNav() {
  return GetAdminListItems();
}
function GetAdminListItems() {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "1rem 0",
        gap: "1rem",
      }}
    >
      {GetCustomListItemButton("/Admin", "Se bestillinger")}
      {GetCustomListItemButton("/Admin/NyttProdukt", "Legg til produkt")}
      {GetCustomListItemButton("/Admin/Kategorier", "Legg til kategori")}
      {GetCustomListItemButton("/Admin/Produkter", "Se produkter")}
      <Button
        size="large"
        color="error"
        style={{ textTransform: "none" }}
        variant="outlined"
        onClick={LogOut}
      >
        Logg ut
      </Button>
    </Box>
  );
}

function GetCustomListItemButton(path: string, title: string) {
  const match = useMatch(path);
  return (
    <Button
      size={match ? "large" : "small"}
      color="primary"
      style={{ textTransform: "none" }}
      variant={match ? "contained" : "outlined"}
      href={path}
      target="_blank"
    >
      {title}
    </Button>
  );
}
