import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { GetChip } from "../../Helpers/CustomChip";
import { IProduct, IShoppingCart } from "../../Models/Model";
import { setProductShoppingCart } from "../../Store/ProductsStore";
import { CustomButton } from "../../styles/CustomButton";
import { QuantityCounter } from "../Inputs/QuantityCounter";
import "./ProductContainer.scss";
import ProductInfoDialog from "./ProductInfoDialog";

interface ProductProps {
  IsFavoriteComponent: boolean;
  product: IProduct;
  shoppingCartItems: IShoppingCart[];
}

export function ProductContainer(props: ProductProps) {
  const { product, IsFavoriteComponent, shoppingCartItems } = props;
  const [isOpenProductInfoDialog, setOpenProductInfoDialog] = useState(false);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));

  var shoppingCartItem = shoppingCartItems.find(
    (item: IShoppingCart) => item.product.productId === product.productId
  );
  var isInShoppingCart = shoppingCartItem !== undefined;
  var shoppingCartQuantity =
    shoppingCartItem !== undefined ? shoppingCartItem.quantity : 0;

  const addProductToShoppingCart = () => {
    dispatch(
      setProductShoppingCart({
        product: product,
        quantity: 1,
        isMixMithaiBox: false,
      })
    );
  };

  const openProductInfoDialog = (open: boolean) => {
    setOpenProductInfoDialog(open);
  };

  const updateShoppingCartQuantity = (quantity: any) => {
    dispatch(
      setProductShoppingCart({
        product: product,
        quantity: quantity,
        isMixMithaiBox: false,
      })
    );
  };

  return (
    <Box height={"100%"} sx={{ position: "relative" }}>
      <Card
        className="product__container__card"
        sx={{
          opacity: product.isSoldOut ? "50%" : null,
        }}
      >
        <CardActionArea onClick={() => openProductInfoDialog(true)}>
          <CardMedia
            className="product__container__media"
            component="img"
            src={product.images[0] ? product.images[0].url : "/noImage.png"}
            loading="lazy"
            alt={"image of " + product.name}
          />
          <CardContent className="product__container__card__content">
            <Box className="product__container__info">
              <Typography
                className={
                  IsFavoriteComponent
                    ? "product__container__favorite__title one-line-overflow"
                    : "product__container__title one-line-overflow"
                }
              >
                {product.name}
              </Typography>

              <Box className="product__container__price__kg">
                <Typography
                  className={
                    IsFavoriteComponent
                      ? "product__container__favorite__price"
                      : "product__container__price"
                  }
                >
                  {product.isOnSale ? product.salePrice : product.price} Kr
                </Typography>
                <Typography
                  className={
                    IsFavoriteComponent
                      ? "product__container__favorite__unit"
                      : "product__container__unit"
                  }
                >
                  /{product.unit}
                </Typography>
              </Box>
            </Box>

            {isDesktop ? (
              <Typography
                className={
                  IsFavoriteComponent
                    ? "product__container__favorite__description two-line-overflow"
                    : "product__container__description two-line-overflow"
                }
              >
                {product.description}
              </Typography>
            ) : (
              IsFavoriteComponent && (
                <Typography className="product__container__favorite__description two-line-overflow">
                  {product.description}
                </Typography>
              )
            )}
          </CardContent>
        </CardActionArea>

        <CardActions className="product__container__card__actions">
          {isInShoppingCart && (
            <QuantityCounter
              setValue={updateShoppingCartQuantity}
              unit={product.unit}
              quantity={shoppingCartQuantity}
            />
          )}

          {!isInShoppingCart && (
            <CustomButton
              className={"product__container__shopping__cart"}
              sx={{
                opacity: product.isSoldOut ? "50%" : null,
                cursor: product.isSoldOut ? "default" : "pointer",
              }}
              onClick={() => {
                addProductToShoppingCart();
              }}
              disabled={product.isSoldOut}
            >
              <AddShoppingCartOutlinedIcon />
            </CustomButton>
          )}
        </CardActions>
      </Card>

      {!IsFavoriteComponent && GetChip(product, false)}

      {isOpenProductInfoDialog && (
        <ProductInfoDialog
          product={product}
          setOpenProductInfoDialog={setOpenProductInfoDialog}
          isOpenProductInfoDialog={isOpenProductInfoDialog}
        ></ProductInfoDialog>
      )}
    </Box>
  );
}