import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Units } from "../../Models/Enum";
import { IImage, IProduct } from "../../Models/Model";
import {
  openSideDrawer,
  setProductShoppingCart,
} from "../../Store/ProductsStore";
import { Breadcrumbs } from "../Navigation/Breadcrumbs";
import { PickAndMixProduct } from "./PickAndMixProduct";
import Loader from "../../Helpers/Loader";
import { NothingHere } from "../../Helpers/NothingHere";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation } from "react-router-dom";
import { getProductsByCategory } from "../../Logic/ProductService";
import "./PickAndMixProducts.scss";
import { QuantityCounter } from "../Inputs/QuantityCounter";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";

export const getMaxProducts = 5;
export const mithaiId = -1;

export function PickAndMixProducts() {
  const mithaiPrice = 170; //TODO HENTE FRA EN BASE KANSKJE ?
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);
  const [weight, setWeight] = useState<number>(1);
  const dispatch = useDispatch();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const location = useLocation();
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    fetchData();
  }, [location.state]);

  const fetchData = async () => {
    if (products.length == 0) {
      const productsApi = (await getProductsByCategory("Mithai")) as IProduct[];
      if (Array.isArray(productsApi) && productsApi.length !== 0) {
        setProducts(productsApi);
        setLocationValues(productsApi);
      }
    } else {
      setLocationValues(products);
    }
    setLoaded(true);
  };

  const handleClose = () => {
    setDialog(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const setLocationValues = (product: IProduct[]) => {
    if (location.state) {
      const weight = (location.state as { quantity: number }).quantity;
      setWeight(weight);

      const locationItems = (location.state as { description: string })
        .description;
      const locationItemsList = locationItems.split(",").map((p) => p.trim());
      const itemsAlreadySelected = product.filter((p) =>
        locationItemsList.includes(p.name),
      );
      setSelectedProducts(itemsAlreadySelected);
    }
  };

  const setProductSelectedState = (product: IProduct) => {
    const tempSelectedProducts = [...selectedProducts];

    const findSelectedProduct = tempSelectedProducts.find(
      (t) => t.productId === product.productId,
    );

    if (findSelectedProduct) {
      const updatedProducts = tempSelectedProducts.filter(
        (x) => x.productId !== product.productId,
      );
      setSelectedProducts(updatedProducts);
      return;
    }

    if (selectedProducts.length < getMaxProducts) {
      setSelectedProducts((prevArray) => [...prevArray, product]);
    } else {
      setDialog(true);
    }
  };

  const mithaiBoxImage = (): IImage => {
    const image: IImage = {
      url: "/mithaiBox.png",
    };
    return image;
  };

  const addMithaiToShoppingCart = () => {
    const product: IProduct = {
      categoryName: "Mithai",
      productId: location.state
        ? (location.state as { productId: string }).productId
        : Guid.create().toString(),
      name: "Mithai plukk og miks",
      description: getDescription(),
      images: [mithaiBoxImage()],
      price: mithaiPrice,
      unit: Units.kg,
      isOnSale: false,
      salePrice: 0,
      isSoldOut: false,
      isBestSeller: false,
      attributes: [],
      sortIndex: 0,
    };

    dispatch(
      setProductShoppingCart({
        product: product,
        quantity: weight,
        isMixMithaiBox: true,
      }),
    );
    setSelectedProducts([]);
    dispatch(openSideDrawer());
    setWeight(1);
    location.state = null;
  };

  const getDescription = () => {
    const mithaiProducts = [...selectedProducts];
    let mithaiStringName = "";

    for (let i = 0; i < mithaiProducts.length; i++) {
      mithaiStringName += mithaiProducts[i].name;

      if (i + 1 !== mithaiProducts.length) {
        mithaiStringName += " , ";
      }
    }
    return mithaiStringName;
  };

  return (
    <>
      {!isLoaded && <Loader />}

      {isLoaded && products.length === 0 && <NothingHere />}

      {isLoaded && products.length !== 0 && (
        <Box>
          <Container maxWidth="xl">
            <Breadcrumbs location={"Plukk og miks"} logList={["Mithai"]} />

            <Box className="pickAndMix__upper__wrapper">
              <Box className="pickAndMix__info__wrapper">
                <Typography className="pickAndMix__info__title">
                  Lag din egen Mithai eske
                </Typography>
                <Typography className="pickAndMix__info__subtitle">
                  Legg opptil 5 forskjellige smaker i en eske
                </Typography>
              </Box>
              <Box className="pickAndMix__selected__outside__wrapper">
                {QuantityAndAddToCart()}
                {ChosenProducts()}
              </Box>
            </Box>

            {dialog && DialogBox()}

            <Box>
              <Grid
                container
                rowSpacing={5}
                columnSpacing={{ xs: 3, lg: 5 }}
                columns={{ xs: 2, sm: 3, md: 4, lg: 5 }}
              >
                {products.map((product: IProduct) => (
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    lg={1}
                    xl={1}
                    key={product.productId}
                    position="relative"
                  >
                    <PickAndMixProduct
                      key={product.productId}
                      setProductSelectedState={setProductSelectedState}
                      product={product}
                      selectedProducts={selectedProducts}
                    ></PickAndMixProduct>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );

  function QuantityAndAddToCart() {
    return (
      <Box className="pickAndMix__quantity__wrapper">
        <QuantityCounter quantity={weight} setValue={setWeight} unit={"kg"} />
        <Typography className="pickAndMix__price">
          {mithaiPrice * weight} kr
        </Typography>
        <Button
          disabled={selectedProducts.length == 0 || weight == 0}
          variant="contained"
          onClick={addMithaiToShoppingCart}
          className="pickAndMix__addBtn"
          endIcon={<AddShoppingCartOutlinedIcon />}
        >
          Legg til
        </Button>
      </Box>
    );
  }

  function ChosenProducts() {
    return (
      <>
        {selectedProducts.length > 0 ? (
          <Box className="pickAndMix__selected__inside__wrapper">
            {selectedProducts.map((product: IProduct) => (
              <Box
                key={product.productId}
                className="pickAndMix__selected__items"
              >
                <CheckIcon fontSize="small" sx={{ color: "#56D674" }} />
                {product.name}
              </Box>
            ))}
          </Box>
        ) : (
          <Typography className="pickAndMix__select__placeholder">
            Ting du velger vil vises her
          </Typography>
        )}
      </>
    );
  }

  function DialogBox() {
    return (
      <Dialog onClose={handleClose} open={dialog}>
        <DialogContent>
          <DialogContentText>Maks 5 valg tillat per boks!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "black" }} onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
