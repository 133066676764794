import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { AdminNav } from "../View/Admin/AdminNav";

export function AdminLayout() {
  return (
    <Box sx={{ width: "100%", height: "100%", background: "white" }}>
      <AdminNav />
      <Outlet />
    </Box>
  );
}
