import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { IProduct } from "../../Models/Model";
import { GetChip } from "../../Helpers/CustomChip";
import "./PickAndMixProduct.scss";

interface IPickAndMixProductProps {
  product: IProduct;
  setProductSelectedState(product: IProduct): void;
  selectedProducts: IProduct[];
}

export function PickAndMixProduct(props: IPickAndMixProductProps) {
  const isSelected = props.selectedProducts.includes(props.product);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("lg"));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const setSelectedState = () => {
    props.setProductSelectedState(props.product);
  };

  return (
    <CardActionArea
      sx={{
        height: "100%",
        opacity: props.product.isSoldOut ? "50%" : null,
        position: "relative",
      }}
      disabled={props.product.isSoldOut}
      onClick={setSelectedState}
    >
      {!isSelected && GetChip(props.product, true)}

      {isSelected && (
        <Box className="pickAndMix__mithai__card__selected">
          <CheckIcon
            fontSize={isSmallScreen ? "medium" : "large"}
            sx={{ margin: "auto", position: "relative", color: "white" }}
          />
        </Box>
      )}

      <Card
        variant="outlined"
        className="pickAndMix__mithai__card"
        sx={{
          ...(isSelected && {
            outline: `3px solid #56D674`,
          }),
        }}
      >
        <CardMedia
          className="pickAndMix__mithai__image"
          component="img"
          src={
            props.product.images[0]
              ? props.product.images[0].url
              : "/noImage.png"
          }
          alt={"image of " + props.product.name}
        />
        <CardContent>
          <Typography className="pickAndMix__mithai__card__name">
            {props.product.name}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}
