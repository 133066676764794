import { getIdToken } from "./AccountService";
import { ILastKeyEvaluated, IOrder, IOrderRequest } from "../Models/Model";
import { format } from "date-fns";

export const baseUrl =
  "https://f2a4dvzliwrs6aj4hxzd2ppky40ekljd.lambda-url.eu-north-1.on.aws/";

// export const baseUrl = "https://localhost:63176/";

export const getCategories = async () => {
  const categoriesUrl = baseUrl + "category";
  try {
    const response = await fetch(categoriesUrl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.ok ? response.json() : null;
    //todo her håndterer vi hva som skal vises til brukeren etter at et kall er gjennomført.
  } catch (error) {
    console.log(error, "error");
    return null;
  }
  // parses JSON response into native JavaScript objects
};

export const putProduct = async (product: any) => {
  const productUrl = baseUrl + "product";

  if (getIdToken() !== "") {
    try {
      await fetch(productUrl, {
        method: "PUT", // *GET, POST, PUT, DELETE, etc
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getIdToken()}`,
        },

        body: JSON.stringify(product),
      })
        .then((response) => (response.ok ? response.json() : null))
        .then((item) => console.log(item));
      //todo her håndterer vi hva som skal vises til brukeren etter at et kall er gjennomført.
    } catch (error) {
      console.log(error, "error");
    }
  }

  // parses JSON response into native JavaScript objects
};

export const GetPaginatedProducts = async (lastKeyEvaluated: any) => {
  const paginatedProductRequest: any = {
    limit: 10,
    lastKeyEvaluated: lastKeyEvaluated,
  };

  const productUrl = baseUrl + "product/" + "paginatedProducts";
  try {
    const response = await fetch(productUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paginatedProductRequest),
    });

    var result = response.ok ? response.json() : null;
    return result;
  } catch (error) {
    return null;
  }
};

export const deleteProduct = async (
  categoryName: string,
  productName: string,
) => {
  const productUrl = baseUrl + "/" + categoryName + "/" + productName;

  if (getIdToken() !== "") {
    try {
      const response = await fetch(productUrl, {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc
        headers: {
          "Content-Type": "application/json",
          Authorization: getIdToken(),
        },
      });

      return response.ok;
    } catch (error) {
      return false;
    }
  }
};

export const getProducts = async () => {
  const productsUrl = baseUrl + "product/all";

  try {
    const response = await fetch(productsUrl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc
      headers: {
        "Content-Type": "application/json",
      },
    });
    var result = response.ok ? response.json() : null;
    return result;

    //todo her håndterer vi hva som skal vises til brukeren etter at et kall er gjennomført.
  } catch (error) {
    console.log(error, "error");
    return null;
  }
};

export const getProduct = async (pathName: string) => {
  try {
    const splittedPath = pathName.split("/");
    const category = splittedPath[2];
    const productName = splittedPath[3];
    const productsUrl = baseUrl + "product/" + category + "/" + productName;

    const response = await fetch(productsUrl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.ok ? response.json() : null;

    //todo her håndterer vi hva som skal vises til brukeren etter at et kall er gjennomført.
  } catch (error) {
    console.log(error, "error");
    return null;
  }
};

export const getProductsByCategory = async (
  categoryName: string | undefined,
) => {
  const productsUrl = baseUrl + "product/category/" + categoryName;

  try {
    const response = await fetch(productsUrl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.ok ? response.json() : null;

    //todo her håndterer vi hva som skal vises til brukeren etter at et kall er gjennomført.
  } catch (error) {
    console.log(error, "error");
    return null;
  }
};

export const postOrder = async (order: IOrderRequest) => {
  try {
    const response = await fetch(baseUrl + "order", {
      method: "POST", // *GET, POST, PUT, DELETE, etc
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    });
    var reply = response.ok ? response.json() : null;
    console.log("PUSHET ORDER:", reply);
    return reply;
  } catch (error) {
    console.log(error, "error");
    return null;
  }
};

export const editOrder = async (order: IOrder) => {
  if (getIdToken() !== "") {
    try {
      const response = await fetch(baseUrl + "order", {
        method: "PUT", // *GET, POST, PUT, DELETE, etc
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getIdToken()}`,
        },
        body: JSON.stringify(order),
      });
      return response.ok;
    } catch (error) {
      console.log(error, "error");
    }
  }
  return false;
};

export const getOrders = async (date: Date) => {
  try {
    var dateString = format(date, "dd-MM-yyyy");
    const response = await fetch(baseUrl + "Order/" + dateString, {
      method: "GET", // *GET, POST, PUT, DELETE, etc
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.ok ? response.json() : null;
  } catch (error) {
    console.log(error, "error");
    return null;
  }
};
