import { Box, Typography } from "@mui/material";
import { GetTotalPrice } from "../../../Logic/ProductsCalculation";
import { IShoppingCart } from "../../../Models/Model";
import "./OrderLines.scss";

interface props {
  shoppingCart: IShoppingCart[];
}

export function TotalPriceComponent(props: props) {
  return (
    <Box className="totalPrice">
      <Typography className="totalPriceText" marginLeft={"0.5em"}>
        TOTALT (inkl. mva)
      </Typography>
      <Typography className="totalPriceText">
        {props.shoppingCart.length > 0
          ? " kr " +
            GetTotalPrice(props.shoppingCart)
              .toFixed(2)
              .replace(/[.,]00$/, "")
          : "0 kr"}
      </Typography>
    </Box>
  );
}
