import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { GetSalePercent } from "../../Logic/ProductsCalculation";
import TextFieldNumber from "./TextFieldNumber";

interface TextFieldPriceInterface {
  price: number;
  setPrice: (value: React.SetStateAction<number>) => void;

  isOnSale: boolean;
  setIsOnSale: (value: React.SetStateAction<boolean>) => void;

  priceAfterSale: number;
  setPriceAfterSale: (value: React.SetStateAction<number>) => void;
}

export function TextFieldPrice(props: TextFieldPriceInterface) {
  const [percentage, setPercentage] = useState("");

  useEffect(() => {
    setPercentage(GetSalePercent(props.price, props.priceAfterSale));
  }, [props.price, props.priceAfterSale]);

  const handleSaleChanged = (sale: number) => {
    props.setPriceAfterSale(sale);
    setPercentage(percentage);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignContent: "stretch",
          alignItems: "center",
        }}
      >
        <TextFieldNumber
          label="Pris"
          value={props.price}
          setValue={props.setPrice}
          helperText=""
          unit="kr"
          required={true}
          disabled={false}
        />
        <FormGroup className="checkOutPageCheckbox">
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isOnSale}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.setIsOnSale(event.target.checked);
                }}
              />
            }
            label="Rabatt?"
          />
        </FormGroup>
      </div>
      {props.isOnSale && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignContent: "stretch",
          }}
        >
          <TextFieldNumber
            label="Salg"
            value={Number(percentage)}
            helperText=""
            unit="%"
            required={false}
            disabled={true}
            setValue={undefined}
          />
          <TextFieldNumber
            label="Pris etter salg"
            value={props.priceAfterSale}
            setValue={handleSaleChanged}
            helperText=""
            unit="kr"
            required={false}
            disabled={false}
          />
        </div>
      )}
    </>
  );
}
