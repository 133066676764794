import {
  Typography,
  TextField,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
} from "@mui/material";
import React from "react";
import {
  handlePhoneNumberValidation,
  isphoneNumberValid,
} from "../../Helpers/CustomerHelper";
import { deleteCustomer } from "../../Logic/CustomerService";
import "./DeleteUser.scss";

export function DeleteUser() {
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumberValid, setPhoneNumberValid] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const VerifyUser = async () => {
    if (!isphoneNumberValid(phoneNumber)) {
      setPhoneNumberValid(false);
      return;
    } else {
      handleClickOpen();
    }
  };

  const deleteCustomerFromAPI = async () => {
    const isCustomerDeleted = await deleteCustomer(phoneNumber);
    if (isCustomerDeleted) {
      handleClose();
      setDeleted(true);
      window.scroll(0, 0);
    }
  };

  return (
    <div className="deleteUser">
      <Typography className="deleteUserHeader">
        {deleted ? "Personopplysninger slettet" : "Slette personopplysninger"}
      </Typography>
      {deleted ? (
        <Typography className="deleteUserContent">
          Du har nå blitt slettet ({phoneNumber})
        </Typography>
      ) : (
        <div>
          <Typography className="deleteUserContent">
            <p>
              Den registrerte skal ha rett til å få personopplysninger om seg
              selv slettet av den behandlingsansvarlige, jf. personopplysninger
              § 17. Tilbaketrekking vil ikke påvirke lovligheten av behandlingen
              basert på samtykket før tilbaketrekkingen.
            </p>

            <p>
              Ønsker du å trekke tilbake samtykket ditt, kan du få den
              innehentede informasjonen slettet ved å skrive inn telefonnumeret
              ditt.
            </p>
          </Typography>

          <Container maxWidth="xs">
            <Box display="grid" gap=".8rem">
              <Typography className="deleteUserContent">
                <b>Skriv inn ditt mobilnummer</b>
              </Typography>
              <TextField
                name="Mobile number"
                label="Mobilnummer"
                placeholder="Eksempel: 91919191"
                value={phoneNumber}
                onChange={(e) =>
                  handlePhoneNumberValidation(
                    e,
                    setPhoneNumber,
                    setPhoneNumberValid,
                  )
                }
                error={!phoneNumberValid}
                required={true}
              ></TextField>

              <Button variant="contained" onClick={VerifyUser}>
                Få slettet personopplysninger
              </Button>
            </Box>
          </Container>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              {
                "Er du sikker på at du ønsker å få dine personopplysninger slettet?"
              }
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Hvis du sletter dine personopplysninger blir du slettet fra vår
                databse. Gjelder nummer: <b>{phoneNumber}</b>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Avbryt</Button>
              <Button onClick={deleteCustomerFromAPI}>Godta</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}
