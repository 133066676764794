import { Typography, TextField, Button, Box } from "@mui/material";
import {
  isVerifiedCustomer,
  sendOneTimePassword,
} from "../../../Logic/CustomerService";
import { ICustomer } from "../../../Models/Model";
import "../CheckOut";
import {
  handlePhoneNumberValidation,
  isphoneNumberValid,
} from "../../../Helpers/CustomerHelper";
import { PAGES } from "../../../Models/Enum";
import "./Stepper.scss";
import { useState } from "react";

interface props {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setCustomer: React.Dispatch<React.SetStateAction<ICustomer>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CustomerPhoneNumber(props: props) {
  const { setCustomer, setLoading, setPage } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);

  const newCustomer = (phoneNumber: string) => {
    var newCustomer: ICustomer = {
      name: "",
      phoneNumber: phoneNumber,
      status: "",
    };
    return newCustomer;
  };

  const VerifyUser = async () => {
    if (!isphoneNumberValid(phoneNumber)) {
      setPhoneNumberValid(false);
      return;
    }
    setLoading(true);

    var responseCustomer = (await isVerifiedCustomer(phoneNumber)) as ICustomer;
    console.log(responseCustomer);

    if (
      responseCustomer.status == "404" ||
      responseCustomer.name == undefined
    ) {
      setCustomer(newCustomer(phoneNumber));
      setPage(PAGES.VERIFY);
      var onetimepasswordResponse = await sendOneTimePassword(phoneNumber);
      if (!onetimepasswordResponse) {
        console.log("klarte ikke å sende sms");
      }
    } else {
      setCustomer(responseCustomer);
      setPage(PAGES.INFO);
    }

    setLoading(false);
  };

  return (
    <Box display="grid" gap="1rem">
      <Box marginBottom={"2rem"}>
        <ol className="phoneNrText">
          <li><Typography gutterBottom className="phoneNrText">
            Gå videre for å bestille klikk og hent
          </Typography></li>
          <li><Typography gutterBottom className="phoneNrText">
            Betaling skjer i butikk
          </Typography></li>
          <li><Typography gutterBottom className="phoneNrText">
            Klar innen 15 minutter!
          </Typography></li>
        </ol>
      </Box>

      <Typography className="phoneNrText">
        Skriv inn ditt mobilnummer
      </Typography>
      <TextField
        name="Mobile number"
        label="Mobilnummer"
        placeholder="Eksempel: 46715238"
        className="phoneNrField"
        value={phoneNumber}
        onChange={(e) =>
          handlePhoneNumberValidation(e, setPhoneNumber, setPhoneNumberValid)
        }
        error={!phoneNumberValid}
        helperText={!phoneNumberValid ? "Telefonnumeret må være 8 siffer" : ""}
        required={true}
        FormHelperTextProps={{
          style: {
            backgroundColor: "#fff7f9",
            margin: 0,
            padding: 10,
          },
        }}
      />
      <Button variant="contained" className="phoneNrBtn" onClick={VerifyUser}>
        Gå videre
      </Button>
    </Box>
  );
}
