import { TextField, MenuItem } from "@mui/material";

interface selectBoxProps {
  label: string;
  currentValue: string;
  values: any[];
  setValue: (value: React.SetStateAction<string>) => void;
  helperText: string;
  required: boolean;
}
export default function SelectBox(props: selectBoxProps) {
  return (
    <TextField
      select
      label={props.label}
      value={props.currentValue}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        props.setValue(event.target.value)
      }
      required={props.required}
      helperText={props.helperText}
      margin="normal"
    >
      <MenuItem key={-1} value={""}>
        {"Ingen"}
      </MenuItem>
      {props.values.map((value: any, index: number) => (
        <MenuItem key={index} value={value}>
          {value}
        </MenuItem>
      ))}
    </TextField>
  );
}
