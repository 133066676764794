import React, { useEffect } from "react";
import { getOrders } from "../../../Logic/ProductService";
import { IOrder } from "../../../Models/Model";
import { Order } from "./Order";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { OrderStatus } from "../../../Models/Enum";
import { isSameDate } from "../../../Logic/Date";
import { OrderComponent } from "./OrderComponent";
import Masonry from "react-responsive-masonry";
import sound from "./bellSoundPling.mp3";

export function Orders() {
  const theme = useTheme();
  const [orders, setOrders] = React.useState<IOrder[]>([]);
  const [online, setOnline] = React.useState(false);
  const [date, setDate] = React.useState<Date>(new Date());
  const firstSocketRender = React.useRef(true);
  const newOrders = React.useRef(orders);
  const MINUTE_MS = 60000;
  const Eight_MINUTE_MS = 480000;
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));
  newOrders.current = orders.filter((o) => o.status === OrderStatus.Nytt);

  useEffect(() => {
    fetchOrders();
  }, [date]);

  const updateOrders = (order: IOrder) => {
    const currentOrders = [...orders];

    for (let i = 0; i <= currentOrders.length; i++) {
      if (currentOrders[i].orderId === order.orderId) {
        currentOrders[i].status = order.status;
        break;
      }
    }

    setOrders(currentOrders);
  };

  let audio: HTMLAudioElement;

  const fetchOrders = async () => {
    const response = (await getOrders(date)) as IOrder[];
    if (Array.isArray(response)) {
      setOrders(response);
    } else {
      setOrders([]);
    }
  };

  function connect() {
    audio = new Audio(sound);

    if (firstSocketRender.current) {
      audio.play();
      firstSocketRender.current = false;
    }

    const confirmOrder = (order: IOrder) => {
      setOrders((current) => [...current, order]);
    };

    const socket = new WebSocket(
      "wss://p7f9mfuip8.execute-api.eu-north-1.amazonaws.com/dev"
    );

    socket.addEventListener("open", function (event) {
      fetchOrders();
      setOnline(true);
      console.log("socket open");
      console.log(event);
    });

    socket.addEventListener("message", function (event) {
      var order = JSON.parse(event.data) as IOrder;
      if (order.status === OrderStatus.Nytt) {
        confirmOrder(order);
        audio.play();
        console.log("socket open22");
      } else {
        setOrders((current) =>
          current.map((o: IOrder) => (o.orderId === order.orderId ? order : o))
        );
      }
    });

    socket.addEventListener("error", function (event) {
      console.log(event);
      setOnline(false);
      socket.close();
    });

    socket.addEventListener("close", function (event) {
      console.log("socket close");
      connect();
    });

    const interval = setInterval(() => {
      if (newOrders.current.length > 0) {
        audio.play();
      }
    }, MINUTE_MS);

    function startPingPong() {
      setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify({ type: "ping" }));
        }
      }, Eight_MINUTE_MS);
    }
  }

  const onChangeDate = (date: any) => {
    if (date) {
      setDate(date);
    }
  };

  const getDate = (date: string) => {
    const [day, month, year] = date.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flex="auto" flexWrap="wrap" padding={"1rem"}>
          {online ? (
            <Masonry gutter={"1.5rem"} columnsCount={isDesktop ? 2 : 1} >
              {orders.length !== 0 ? (
                orders
                  .filter(
                    (order: IOrder) =>
                      order.status === OrderStatus.Nytt &&
                      isSameDate(date, getDate(order.date))
                  )
                  .map((order: IOrder, index: number) => (
                    <Order
                      key={index}
                      updateOrders={updateOrders}
                      order={order}
                      dialog={false}
                    ></Order>
                  ))
              ) : (
                <h1>Ingen ordre ennå</h1>
              )}
            </Masonry>
          ) : (
            <Button
              color="success"
              sx={{ margin: "0 auto" }}
              variant="contained"
              onClick={connect}
            >
              Aktiver klikk og hent
            </Button>
          )}
        </Box>
        <Box flex="1fr" textAlign="center" padding=".5rem" margin=".5rem">
          {online && (
            <Box>
              <Button color="success">ONLINE</Button>
            </Box>
          )}
          <Box marginBottom=".5rem">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Date for ordre"
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={(date) => {
                  onChangeDate(date);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
          {!isSameDate(new Date(), date) && (
            <Box>
              <Button
                variant="contained"
                color="error"
                onClick={() => setDate(new Date())}
                sx={{ m: 2 }}
              >
                Se dagens Ordre
              </Button>
            </Box>
          )}
          <Box border={"1px solid " + theme.palette.success.main}>
            <Typography variant="body1" gutterBottom>
              <b>Ferdig pakket </b>
            </Typography>
            {orders
              .filter((order: IOrder) => order.status === OrderStatus.Ferdig)
              .map(
                (order: IOrder) =>
                  isSameDate(date, getDate(order.date)) && (
                    <OrderComponent
                      order={order}
                      updateOrders={updateOrders}
                      key={order.orderId}
                    ></OrderComponent>
                  )
              )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
