import Grid from "@mui/material/Grid";
import { IProduct, IShoppingCart } from "../../Models/Model";
import { ProductContainer } from "./ProductContainer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Box, useMediaQuery, useTheme } from "@mui/material";

interface IProductsProps {
  products: IProduct[];
  columns: any;
  isFavoriteComponent: boolean;
  shoppingCartItems: IShoppingCart[];
}

export function ProductsGrid(props: IProductsProps) {
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));

  if (props.isFavoriteComponent) {
    return (
      <Carousel
        centerMode
        centerSlidePercentage={isDesktop ? 30 : 80}
        showStatus={false}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={15}
      >
        {props.products &&
          props.products.map((product: IProduct, index: number) => {
            return (
              <Box
                margin={"1rem"}
                paddingBottom={"2rem"}
                key={product.productId}
              >
                <ProductContainer
                  IsFavoriteComponent={props.isFavoriteComponent}
                  product={product}
                  shoppingCartItems={props.shoppingCartItems}
                />
              </Box>
            );
          })}
      </Carousel>
    );
  } else {
    return (
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 2, md: 4 }}
        columns={props.columns}
      >
        {props.products &&
          props.products.map((product: IProduct, index: number) => {
            return (
              <Grid
                item
                xs={1}
                sm={1}
                lg={1}
                xl={1}
                key={index}
                position="relative"
              >
                <ProductContainer
                  IsFavoriteComponent={props.isFavoriteComponent}
                  product={product}
                  key={product.productId}
                  shoppingCartItems={props.shoppingCartItems}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  }
}
