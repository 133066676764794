import { TextField } from "@mui/material";
import { IProductAttribute } from "./ProductAttribute";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

interface ProductAttributeProps {
  productAttribute: IProductAttribute;
  onClickRemoveAttribute(id: string): void;
  onClickAddAttribute(): void;
  onChangedAttribute(attribute: IProductAttribute): void;
  index: number;
}

export default function ProductAttributeItem(props: ProductAttributeProps) {
  const onKeyChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const attribute = { ...props.productAttribute };
    attribute.key = e.target.value;
    props.onChangedAttribute(attribute);
  };

  const onValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const attribute = { ...props.productAttribute };
    attribute.value = e.target.value;
    props.onChangedAttribute(attribute);
  };

  return (
    <div className="productAttribute">
      <TextField
        label="EgenskapsNøkkel"
        onChange={onKeyChanged}
        size="small"
        margin="dense"
        value={props.productAttribute.key}
      />
      <TextField
        label="Egenskapsverdi"
        onChange={onValueChanged}
        size="small"
        margin="dense"
        value={props.productAttribute.value}
      />
      {props.index == 0 && (
        <AddCircleIcon
          sx={{ alignSelf: "center" }}
          onClick={props.onClickAddAttribute}
        />
      )}
      {props.index !== 0 && (
        <RemoveCircleIcon
          onClick={() =>
            props.onClickRemoveAttribute(props.productAttribute.id)
          }
          sx={{ alignSelf: "center" }}
        ></RemoveCircleIcon>
      )}
    </div>
  );
}
