import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Container,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./App.scss";
import Loader from "./Helpers/Loader";
import { storeIsClosing, storeIsOpen } from "./Logic/ClosingHours";
import { GetPaginatedProducts } from "./Logic/ProductService";
import { IPaginatedProduct, IProduct, IShoppingCart } from "./Models/Model";
import { PickAndMixAdvertising } from "./View/PickAndMix/PickAndMixAdvertising";
import { ProductsGrid } from "./View/Product/ProductsGrid";
import { LoadingButton } from "./Helpers/LoadingButton";
import "./styles/mixins.scss";

interface mainPageProps {
  buttonLoader: boolean;
  setButtonLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MainPage(props: mainPageProps) {
  const [paginatedProduct, setPaginatedProduct] = useState<IPaginatedProduct>();
  const [bestsellers, setBestsellers] = useState<IProduct[]>([]);
  const [isStoreOpen, setIsStoreOpen] = useState(storeIsOpen);
  const [isStoreClosing, setIsStoreClosing] = useState(storeIsClosing);
  const [isLoaded, setLoaded] = useState(false);

  const { buttonLoader, setButtonLoader } = props;

  useEffect(() => {
    fetchData();
  }, []);

  const shoppingCartItems = useSelector(
    (state: any) => state.ProductStore.shoppingCart as IShoppingCart[]
  );

  const fetchData = async () => {
    const response: IPaginatedProduct = await GetPaginatedProducts(null);

    if (response != null) {
      if (response.bestsellers != null) {
        const bestSellerWithOutSoldOut = response.bestsellers.filter(
          (item) => !item.isSoldOut
        );
        setBestsellers(bestSellerWithOutSoldOut);
      }

      if (response.products != null) {
        response.products = response.products.filter(
          (item) => !item.isBestSeller
        );
        setPaginatedProduct(response);
      }
    }

    setLoaded(true);
  };

  const loadProducts = async () => {
    setButtonLoader(true);

    const response: IPaginatedProduct = await GetPaginatedProducts(
      paginatedProduct!.lastKeyEvaluated
    );

    if (response != null && response.products.length > 0) {
      const extendedProducts = [...paginatedProduct!.products].concat(
        response.products.filter((item) => !item.isBestSeller)
      );
      const updatedPaginatedProduct: any = {
        lastKeyEvaluated: response.lastKeyEvaluated,
        products: extendedProducts,
      };

      setPaginatedProduct(updatedPaginatedProduct);
    }
    setButtonLoader(false);
  };

  const handleClose = () => {
    setIsStoreOpen(true);
    setIsStoreClosing(false);
  };

  return (
    <>
      {!isLoaded && <Loader></Loader>}
      {isLoaded && (
        <Box>
          {bestsellers && bestsellers.length > 0 ? (
            <Container
              maxWidth="xl"
              sx={{
                marginTop: "2em",
              }}
            >
              <Typography className="main_page_title">
                Velkommen til Yadgaar Sweets - Klikk og hent
              </Typography>

              <Typography className="main_page_subtitle">
                <i> Våre mest solgte produkter</i>
              </Typography>

              <ProductsGrid
                isFavoriteComponent={true}
                columns={{ xs: 1, sm: 2, md: 3 }}
                products={bestsellers}
                shoppingCartItems={shoppingCartItems}
              />
            </Container>
          ) : (
            <></>
          )}

          <PickAndMixAdvertising />

          <Container
            maxWidth="xl"
            sx={{
              marginTop: "2rem",
              marginBottom: "5rem",
              textAlign: "center",
            }}
          >
            <Typography className="main_page_subtitle">
              <i> Se våre andre produkter</i>
            </Typography>
            {paginatedProduct && (
              <>
                <ProductsGrid
                  isFavoriteComponent={false}
                  columns={{ xs: 2, sm: 2, md: 3, xl: 4 }}
                  products={paginatedProduct.products}
                  shoppingCartItems={shoppingCartItems}
                />

                <LoadingButton
                  loadProducts={loadProducts}
                  buttonLoader={buttonLoader}
                  paginatedProduct={paginatedProduct}
                ></LoadingButton>
              </>
            )}
          </Container>

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={!isStoreOpen || isStoreClosing}
            message={
              !isStoreOpen
                ? "Nettbutikken er dessverre stengt."
                : isStoreClosing
                  ? "Nettbutikken stenger 20:45."
                  : null
            }
            action={
              <IconButton
                size="small"
                aria-label="closeInfoBtn"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            }
          />
        </Box>
      )}
    </>
  );
}
