import { Box, CircularProgress } from "@mui/material";
import { CustomButton } from "../styles/CustomButton";
import { IPaginatedProduct } from "../Models/Model";

interface ILoadingButtonProps {
  buttonLoader: boolean;
  loadProducts: () => Promise<void>;
  paginatedProduct: IPaginatedProduct;
}

export const LoadingButton = (props: ILoadingButtonProps) => {
  const { buttonLoader, loadProducts, paginatedProduct } = props;

  if (buttonLoader) {
    return (
      <CustomButton className="orderLineBtn continueShoppingBtn showMoreItemsButton" sx={{ marginTop: "2em" }}
      >
        <Box component="span"> Laster</Box>
        <CircularProgress sx={{ color: "#393939" }} />
      </CustomButton>
    );
  } else if (paginatedProduct.lastKeyEvaluated !== null) {
    return (
      <CustomButton
        onClick={loadProducts}
        className="orderLineBtn continueShoppingBtn "
        sx={{ marginTop: "1em" }}
      >
        Vis flere
      </CustomButton>
    );
  } else {
    return null;
  }
};
