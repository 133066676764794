import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { authorizeUser } from "../../Logic/LoginService";
import { useState } from "react";
import { LogInDetails } from "../../Models/Model";
import { useNavigate } from "react-router-dom";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [wrongUserDetails, setWrongUserDetails] = useState(false);
  const navigate = useNavigate();

  const onSubmitLogin = () => {
    const userDetails: LogInDetails = {
      username: email,
      password: password,
    };

    authorizeUser(userDetails, navigate).catch(function (error) {
      // Promise rejected
      setWrongUserDetails(true);
    });
  };

  const onChangeUserName = (value: string) => {
    setEmail(value);
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
  };

  return (
    <div>
      <h1>Logg inn</h1>
      <div className="logIn">
        <TextField
          id="outlined-username-input"
          label="Brukernavn"
          type="text"
          autoComplete="current-username"
          onChange={(e) => onChangeUserName(e.target.value)}
        />

        <TextField
          id="outlined-password-input"
          label="Passord"
          type="password"
          autoComplete="current-password"
          onChange={(e) => onChangePassword(e.target.value)}
        />
      </div>
      <Button
        variant="contained"
        className="logInButton"
        onClick={onSubmitLogin}
      >
        Logg inn
      </Button>

      {wrongUserDetails && <p>Brukernavnet eller passordet er feil</p>}
    </div>
  );
}
