import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import { styled } from "@mui/system";

export const CustomButton = styled(ButtonUnstyled)`
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
`;
