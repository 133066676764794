import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategory } from "../Models/Model";

const categorySlice = createSlice({
  name: "CategoryStore",
  initialState: { categories: [], selectedCategory: "" },
  reducers: {
    setCategories(state: any, action: PayloadAction<ICategory[]>) {
      state.categories = action.payload;
    },
    setSelectedCategory(state: any, action: PayloadAction<string>) {
      state.selectedCategory = action.payload;
    },
  },
});

export const { setCategories, setSelectedCategory } = categorySlice.actions;

export default categorySlice.reducer;
