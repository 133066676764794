import { Box, Button, Typography, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../Models/Enum";
import { IShoppingCart } from "../../../Models/Model";
import {
  closeSideDrawer,
  emptyShoppingCart,
} from "../../../Store/ProductsStore";
import { OrderLine } from "./OrderLine";
import { TotalPriceComponent } from "./TotalPriceComponent";
import { useMediaQuery } from "@mui/material";
import "./OrderLines.scss";

interface props {
  shoppingCart: IShoppingCart[];
  page: PAGES;
  isSideDrawer: boolean;
}
export function OrderLines(props: props) {
  const { shoppingCart, page, isSideDrawer } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));

  const continueShopping = () => {
    if (
      window.location.pathname === "/" ||
      window.location.href.includes("Kategori")
    ) {
      dispatch(closeSideDrawer());
    } else {
      navigate("/");
    }
  };

  return (
    <Box className="orderLines">
      <Button
        className="orderLinesEmptyCartBtn"
        onClick={() => dispatch(emptyShoppingCart())}
      >
        Tøm handlekurven
      </Button>
      {isDesktop && (
        <Box className="orderLinesTopBox">
          <Typography className="orderLinesTopText padding">Produkt</Typography>
          <Typography className="orderLinesTopText padding">Antall</Typography>
          <Typography className="orderLinesTopText ">Pris</Typography>
        </Box>
      )}
      <Box className="orderLineProducts">
        {shoppingCart.map((cartItem: IShoppingCart, index: number) => (
          <OrderLine
            key={index}
            shouldHaveLine={index !== shoppingCart.length - 1}
            shoppingCart={cartItem}
          ></OrderLine>
        ))}
      </Box>

      {shoppingCart.length > 0 && (
        <TotalPriceComponent shoppingCart={shoppingCart}></TotalPriceComponent>
      )}

      {page == PAGES.PHONENR && (
        <Box className="orderLinesButtons">
          <Button
            className="orderLineBtn continueShoppingBtn"
            variant="outlined"
            onClick={() => {
              continueShopping();
            }}
          >
            Fortsett å handle
          </Button>

          {isSideDrawer && (
            <Button
              className="orderLineBtn goToShoppingCartBtn"
              variant="outlined"
              sx={{ mb: isDesktop ? 0 : "5em" }}
              onClick={() => {
                navigate("/handlekurv");
                dispatch(closeSideDrawer());
              }}
            >
              {isDesktop ? "Gå til handlekurv" : "Gå videre"}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
