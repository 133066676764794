import { ICustomer, IOrder } from "../Models/Model";
import { baseUrl } from "./ProductService";

export const deleteCustomer = async (phoneNumber: string) => {
  const customerUrl = baseUrl + "customer?phoneNumber=" + phoneNumber;
  try {
    const response = await fetch(customerUrl, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc
    });

    return response.ok;
  } catch (error) {
    return false; // todo kan skrive melding til kunde
  }
};

export const updateCustomer = async (customer: ICustomer) => {
  const customerUrl = baseUrl + "customer";

  const customerRequest = {
    phoneNumber: customer.phoneNumber,
    name: customer.name,
    status: customer.status,
  };

  try {
    await fetch(customerUrl, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc
      body: JSON.stringify(customerRequest),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((item) => console.log(item));
  } catch (error) {
    console.log(error);
  }
};

export async function SendOrderConfirmation(order: IOrder) {
  try {
    var response = await fetch(
      baseUrl +
        "customer/orderconfirmation/" +
        order.customer.phoneNumber +
        "/" +
        order.pickUpId,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.ok;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const sendSMSToCustomer = async (order: IOrder) => {
  const customURL =
    baseUrl + "Customer/" + order.customer.phoneNumber + "/" + order.message;

  try {
    const response = await fetch(customURL, {
      method: "GET", // *GET, POST, PUT, DELETE, etc
      headers: {
        accept: "application/json",
      },
    });

    return response.ok;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export async function verifyCustomer(customer: ICustomer, password: string) {
  try {
    const response = await fetch(
      `${baseUrl}customer/verify/${customer.phoneNumber}/${password}`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.ok ? response.json() : null;
  } catch (error) {
    return null;
  }
}

export async function isVerifiedCustomer(phoneNumber: string) {
  try {
    const response = await fetch(baseUrl + "customer/status/" + phoneNumber, {
      method: "GET", // *GET, POST, PUT, DELETE, etc
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json();
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function sendOneTimePassword(phoneNumber: string) {
  try {
    const response = await fetch(
      baseUrl + "customer/onetimepassword/" + phoneNumber,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.ok;
  } catch (error) {
    return false;
  }
}
