import { Breadcrumbs as BreadcrumbsMui, Typography, Link } from "@mui/material";

interface props {
  location: string;
  logList: string[];
}

export function Breadcrumbs(props: props) {
  return (
    <BreadcrumbsMui sx={{ margin: "1rem 0" }}>
      <Link underline="hover" color="inherit" href="/">
        Hjem
      </Link>
      {props.logList.map((log: string, index: number) => (
        <Link
          underline="hover"
          key={index}
          color="inherit"
          href={`/Kategori/${log}`}
        >
          {log}
        </Link>
      ))}
      <Typography color="text.primary">{props.location}</Typography>
    </BreadcrumbsMui>
  );
}
