import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Components/OrderLines.scss";

export function EmptyCheckOut() {
  const navigate = useNavigate();
  return (
    <Box className="emptyCheckOut">
      <Typography gutterBottom className="emptyCheckOutText">
        Du har ingen produkter i handlekurven
      </Typography>
      <Button
        className="orderLineBtn continueShoppingBtn"
        variant="outlined"
        onClick={() => navigate("/")}
      >
        Fortsett å handle
      </Button>
    </Box>
  );
}
