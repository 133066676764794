import { Guid } from "guid-typescript";
import ProductAttributeItem from "./ProductAttributeItem";

export interface IProductAttribute {
  id: string;
  key: string;
  value: string;
}
interface ProductAttributeProps {
  setValue: (value: React.SetStateAction<IProductAttribute[]>) => void;
  attributes: IProductAttribute[];
}

export function ProductAttribute(props: ProductAttributeProps) {
  const { attributes, setValue } = props;

  const onClickAddAttribute = () => {
    const attributesState = [...attributes];
    attributesState.push({
      id: Guid.create().toString(),
      key: "",
      value: "",
    });

    setValue(attributesState);
  };

  const onClickRemoveAttribute = (id: string) => {
    const attributeState = [...attributes].filter(
      (attribute: { id: string }) => attribute.id != id,
    );

    props.setValue(attributeState);
  };

  const onChangedAttribute = (attribute: IProductAttribute) => {
    const attributesArray = [...attributes];

    for (var i = 0; i < attributesArray.length; i++) {
      if (attributesArray[i].id == attribute.id) {
        attributesArray[i] = attribute;
      }
    }
    props.setValue(attributesArray);
  };

  return (
    <>
      {attributes.map((attribute: IProductAttribute, index: number) => (
        <ProductAttributeItem
          productAttribute={attribute}
          onChangedAttribute={onChangedAttribute}
          onClickRemoveAttribute={onClickRemoveAttribute}
          onClickAddAttribute={onClickAddAttribute}
          key={attribute.id}
          index={index}
        />
      ))}
    </>
  );
}
