import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct, IShoppingCart } from "../Models/Model";

export const productSlice = createSlice({
  name: "ProductStore",
  initialState: {
    shoppingCart: [],
    sideDrawerIsOpen: false,
  },
  reducers: {
    //sideDrawer
    openSideDrawer(state: any) {
      state.sideDrawerIsOpen = true;
    },
    closeSideDrawer(state: any) {
      state.sideDrawerIsOpen = false;
    },
    //ShoppingCart
    emptyShoppingCart(state: any) {
      state.shoppingCart = [];
    },
    removeProductShoppingCart(
      state: any,
      action: PayloadAction<{ productId: string }>,
    ) {
      state.shoppingCart = state.shoppingCart.filter(
        (s: IShoppingCart) => action.payload.productId !== s.product.productId,
      );
    },

    setProductShoppingCart(
      state: any,
      action: PayloadAction<{
        product: IProduct;
        quantity: number;
        isMixMithaiBox: boolean;
      }>,
    ) {
      const product = action.payload.product;
      const shoppingCartItems = [...state.shoppingCart];

      if (productExistInShoppingCart(shoppingCartItems, product.productId)) {
        if (action.payload.quantity === 0) {
          state.shoppingCart = state.shoppingCart.filter(
            (s: IShoppingCart) => product.productId !== s.product.productId,
          );
        } else {
          const shoppingCartItem: IShoppingCart = getProduct(
            [...state.shoppingCart],
            action.payload.product.productId,
          );

          shoppingCartItem.quantity = action.payload.quantity;
          shoppingCartItem.product.description =
            action.payload.product.description;
        }
      } else {
        const ItemToShoppingCart: IShoppingCart = {
          product: product,
          quantity: action.payload.quantity,
          mixMithaiBox: action.payload.isMixMithaiBox,
        };
        state.shoppingCart.push(ItemToShoppingCart);
      }
    },
  },
});

export const {
  emptyShoppingCart,
  removeProductShoppingCart,
  setProductShoppingCart,
  openSideDrawer,
  closeSideDrawer,
} = productSlice.actions;

export default productSlice.reducer;

//Functions

function getProduct(
  shoppingCartItems: any[],
  productId: string,
): IShoppingCart {
  return shoppingCartItems.find(
    (x: IShoppingCart) => x.product.productId === productId,
  );
}

function productExistInShoppingCart(
  shoppingCartItems: IShoppingCart[],
  productId: string,
) {
  return (
    shoppingCartItems &&
    shoppingCartItems.some(
      (x: IShoppingCart) => x.product.productId === productId,
    )
  );
}
