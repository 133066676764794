import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  GetProductQuantity,
  GetTotalPrice,
} from "../../Logic/ProductsCalculation";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import { ICategory } from "../../Models/Model";
import SideDrawer from "./SideDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import { YadgaarLogo } from "./YadgaarLogo";
import Typography from "@mui/material/Typography";
import { openSideDrawer } from "../../Store/ProductsStore";
import "./NavBar.scss";
import { useEffect, useState } from "react";
import { CustomButton } from "../../styles/CustomButton";
import CloseIcon from '@mui/icons-material/Close';

export function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down(1330));
  const shoppingCart = useSelector(
    (state: any) => state.ProductStore.shoppingCart,
  );

  const categoryNotInOrder = useSelector(
    (state: any) => state.CategoryStore.categories,
  ) as ICategory[];
  const [categories, setCategories] = useState<ICategory[]>();
  const sortOrder: string[] = [
    //TODO: Lage en GUI på dette, slik at admin selv kan velge rekkefølgen på hvordan det skal vises
    "Hjem",
    "Mithai",
    "Naan",
    "Mat og drikke",
    "Dessert",
  ];

  useEffect(() => {
    const copyCategory = [...categoryNotInOrder];
    if (copyCategory.length > 0) {
      const categoriesWithPosition = copyCategory.sort(
        (a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name),
      );
      setCategories(categoriesWithPosition);
    }
  }, [categoryNotInOrder]);

  return (
    <AppBar component="nav" className="navBar">
      <SideDrawer />
      <Toolbar className="toolBar" disableGutters>
        {isSmallScreen && <MobileMenu />}
        <LogoLayOut />
        {!isSmallScreen && <DesktopMenu />}
        <ShoppingCart />
      </Toolbar>
    </AppBar>
  );

  function DesktopMenu() {
    return (
      <Box>
        {categories &&
          categories.map((category: ICategory) => (
            <CustomButton
              className={
                category.name == "Hjem" && location.pathname == "/"
                  ? "desktopNav chosen"
                  : location.pathname == "/Kategori/" + category.url
                    ? "desktopNav chosen"
                    : "desktopNav"
              }
              key={category.name}
              onClick={() => navigate("Kategori/" + category.url)}
            >
              {category.name}
            </CustomButton>
          ))}
      </Box>
    );
  }

  function MobileMenu() {
    const [openCloseMenu, setOpenCloseMenu] = useState(false);

    const handleDrawerToggle = () => {
      setOpenCloseMenu((prevState) => !prevState);
    };

    const drawer = (
      <Box className="mobileMenu">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CloseIcon onClick={handleDrawerToggle} sx={{ cursor: "pointer" }} />
          <Box sx={{ flex: "1", textAlign: "center" }}>
            <LogoLayOut />
          </Box>
        </Box>
        <Divider />
        <List>
          {categories &&
            categories.map((category: ICategory) => (
              <ListItem key={category.name}>
                <ListItemButton
                  onClick={() => navigate("Kategori/" + category.url)}
                >
                  <ListItemText
                    primary={
                      <Typography className="mobileNav">
                        {category.name}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
    );

    return (
      <Box>
        <IconButton
          aria-label="open menu"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          variant="temporary"
          open={openCloseMenu}
          anchor="right"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    );
  }

  function LogoLayOut() {
    return (
      <Box className="logoLayOut" onClick={() => navigate("")}>
        <YadgaarLogo />
        <Typography className="navBarTitle">Yadgaar Sweets</Typography>
      </Box>
    );
  }

  function ShoppingCart() {
    return (
      <CustomButton
        className="shoppingCart"
        onClick={() => dispatch(openSideDrawer())}
      >
        <Box className="shoppingCartNav">
          <ShoppingCartOutlined />

          {!isSmallScreen && (
            <>
              <span>Handlekurv</span>
              {GetTotalPrice(shoppingCart)} {" kr"}
            </>
          )}

          <span className="shoppingCartBadge">
            {GetProductQuantity(shoppingCart)}
          </span>
        </Box>
      </CustomButton>
    );
  }
}
