import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GoogleIcon from "@mui/icons-material/Google";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Container, Typography } from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import "./Footer.scss";

export function Footer() {
  return (
    <Box className="footer">
      <Container maxWidth="xl">
        <Box className="footerWrapper">
          <Box>
            <Typography className="footerHeader" gutterBottom component="div">
              Åpningstider
            </Typography>
            <Typography className="footerInfo" component="div">
              Mandag - Lørdag
            </Typography>
            <Typography
              className="footerInfo withIcon"
              component="div"
              paddingBottom={"1rem"}
            >
              <AccessTimeIcon /> 10:00 - 21:00
            </Typography>
            <Typography className="footerInfo" component="div">
              Søndag
            </Typography>
            <Typography className="footerInfo withIcon" component="div">
              <AccessTimeIcon /> 12:00 - 21:00
            </Typography>
          </Box>

          <Box>
            <Typography gutterBottom className="footerHeader" component="div">
              Kontakt oss
            </Typography>
            <Typography
              className="footerInfo withIcon"
              gutterBottom
              component="div"
            >
              <PhoneIcon />
              <a className="linkStyling" href="tel:+4722682068">
                +47 22 68 20 68
              </a>
            </Typography>
            <Typography className="footerInfo withIcon" component="div">
              <LocationOnIcon />
              <a
                className="linkStyling"
                href="http://www.google.com/maps/place/59.914120,10.768110"
              >
                Tøyengata 27, 0578 Oslo
              </a>
            </Typography>
          </Box>

          <Box>
            <Typography className="footerHeader" gutterBottom component="div">
              Informasjon
            </Typography>
            <Typography className="footerInfo withIcon" gutterBottom>
              <InfoIcon />
              <a className="linkStyling" href="/Omoss">
                Om oss
              </a>
            </Typography>
            <Typography className="footerInfo withIcon" gutterBottom>
              <TextSnippetIcon />
              <a className="linkStyling" href="/personvern" >
                Personvern
              </a>
            </Typography>
            <Typography className="footerInfo withIcon">
              <GoogleIcon />
              <a
                className="linkStyling"
                href="https://www.google.com/search?q=Yadgaar+Sweets+%26+Bakers+AS&rlz=1C1ONGR_noNO1015NO1015&sxsrf=ALiCzsYOLIDx9ZJnQ7yvTN3yyedrMl1QCg%3A1658683569387&ei=sYDdYuicF7GExc8Pk6aZmAk&ved=0ahUKEwioucrnhZL5AhUxQvEDHRNTBpMQ4dUDCA4&uact=5&oq=Yadgaar+Sweets+%26+Bakers+AS&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEIAEMgUIABCGAzIFCAAQhgM6BwgAEEcQsANKBAhBGABKBAhGGABQpgNYpgNgtAhoA3ABeACAAUCIAUCSAQExmAEAoAECoAEByAEGwAEB&sclient=gws-wiz"
              >
                Yadgaar Sweets &amp; Bakers AS
              </a>
            </Typography>
          </Box>
        </Box>

        <Box className="copyright">
          <a
            className="linkStyling"
            href="https://www.webforyou.no"
            target="_blank"
            rel="noreferrer"
            title="Besøk vårt nettsted!"
          >
            Copyright 2023 &#169; Powered by WebForYou
          </a>
        </Box>
      </Container>
    </Box>
  );
}
