import { Container, Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import ShopClosedComponent from "../../../Helpers/ShopClosedComponent";
import { storeIsOpen } from "../../../Logic/ClosingHours";
import { PAGES } from "../../../Models/Enum";
import { ICustomer, IOrder } from "../../../Models/Model";
import { CustomerForm } from "./CustomerForm";
import { CustomerPhoneNumber } from "./CustomerPhoneNumber";
import { VerifyPhoneForm } from "./VerifyPhoneForm";
import { Stepper } from "./Stepper";
import Loader from "../../../Helpers/Loader";

interface props {
  page: PAGES;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setOrder: React.Dispatch<React.SetStateAction<IOrder | undefined>>;
}

export function StepWrapper(props: props) {
  const { page, setPage, setOrder } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const storeOpen = storeIsOpen();

  const shoppingCart = useSelector(
    (state: any) => state.ProductStore.shoppingCart,
  );

  const [customer, setCustomer] = React.useState<ICustomer>({
    name: "",
    phoneNumber: "",
    status: "",
  });

  return (
    <>
      {storeOpen ? (
        <Container>
          {Stepper(page)}

          {loading ? (
            <Loader />
          ) : (
            <Container maxWidth="sm" sx={{ marginTop: "9.5em" }}>
              <Box>
                {page === PAGES.PHONENR && (
                  <CustomerPhoneNumber
                    setPage={setPage}
                    setLoading={setLoading}
                    setCustomer={setCustomer}
                  ></CustomerPhoneNumber>
                )}

                {page === PAGES.VERIFY && (
                  <VerifyPhoneForm
                    customer={customer}
                    setCustomer={setCustomer}
                    setPage={setPage}
                    isLoading={loading}
                    setLoading={setLoading}
                  />
                )}

                {page === PAGES.INFO && (
                  <CustomerForm
                    setLoading={setLoading}
                    isLoading={loading}
                    shoppingCart={shoppingCart}
                    customer={customer}
                    setCustomer={setCustomer}
                    setPage={setPage}
                    setOrder={setOrder}
                  />
                )}
              </Box>
            </Container>
          )}
        </Container>
      ) : (
        <ShopClosedComponent />
      )}
    </>
  );
}
