import { Typography, Button, Box, TextField } from "@mui/material";
import React from "react";
import { postOrder } from "../../../Logic/ProductService";
import {
  ICustomer,
  IOrder,
  IOrderRequest,
  IShoppingCart,
} from "../../../Models/Model";
import { PAGES } from "../../../Models/Enum";
import "../CheckOut";
import { updateCustomer } from "../../../Logic/CustomerService";
import { storeIsOpen } from "../../../Logic/ClosingHours";
import ShopClosedComponent from "../../../Helpers/ShopClosedComponent";
import "./Stepper.scss";

interface props {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  customer: ICustomer;
  setCustomer: React.Dispatch<React.SetStateAction<ICustomer>>;
  shoppingCart: IShoppingCart[];
  setOrder: React.Dispatch<React.SetStateAction<IOrder | undefined>>;
  setLoading: any;
  isLoading: boolean;
}

export function CustomerForm(props: props) {
  const { shoppingCart, customer, setPage, setCustomer, setOrder } = props;

  const [message, setMessage] = React.useState("");
  const [customerNameIsChanged, setCustomerNameIsChanged] =
    React.useState(false);
  const [customerNameValid, setCustomerNameValid] = React.useState(true);
  const isStoreOpen = React.useState(storeIsOpen);

  function validateCustomerName(name: string): boolean {
    return /\S/.test(name);
  }

  function setCustomerName(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    const isValid = validateCustomerName(e.target.value);
    setCustomerNameValid(isValid);
    setCustomerNameIsChanged(true);
    setCustomer({ ...customer, name: e.target.value });
  }

  async function SendOrder() {
    if (!isStoreOpen) {
      return <ShopClosedComponent />;
    } else {
      if (!customer.name || !customerNameValid) {
        setCustomerNameValid(false);
        return;
      }

      var newOrder: IOrderRequest = {
        shoppingCart: shoppingCart,
        customer: customer,
        message: message,
      };

      if (customerNameIsChanged) {
        updateCustomer(customer);
      }

      props.setLoading(true);
      var orderResponse = await postOrder(newOrder);
      if (orderResponse) {
        setOrder(orderResponse as IOrder);
        setPage(PAGES.COMPLETED);
      } else {
        console.log("Feil i postOrder");
      }
      props.setLoading(false);
    }
  }

  return (
    <Box>
      <Typography className="customerFormText" gutterBottom>
        Legg inn bestilling
      </Typography>
      <Box display="flex" flexDirection="column" marginTop="1rem" gap="1rem">
        <TextField
          name="Navn"
          className="nameTextField"
          label="Navn"
          value={customer.name ? customer.name : ""}
          onChange={(e) => setCustomerName(e)}
          required
          error={!customerNameValid}
          helperText={
            !customerNameValid ? "Vennligst skriv inn navnet ditt" : ""
          }
          FormHelperTextProps={{
            style: {
              backgroundColor: "#fff7f9",
              margin: 0,
              padding: 10,
            },
          }}
        ></TextField>
        <TextField
          name="Melding"
          className="messageTextField"
          label="Du kan legge til dine ønsker"
          placeholder="Uten løk og tomat.."
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></TextField>
      </Box>
      <br />
      <Button
        variant="contained"
        id="customerFormBtn"
        className="customerFormBtn"
        fullWidth
        onClick={async () => await SendOrder()}
      >
        Klikk og hent
      </Button>
    </Box>
  );
}
