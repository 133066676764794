import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { IImage } from "../../Models/Model";
interface props {
  files: any[];
  setFiles: (file: any[]) => void;
}
export function Dropzone(props: props) {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDropAccepted: async (files: File[]) => await handleFiles(files),
  });

  const handleFiles = async (files: File[]) => {
    const result: any[] = [];
    for (const imageFile of files) {
      console.log(imageFile);
      const bytes = await getBase64(imageFile);
      result.push({
        url: imageFile.name,
        content: bytes,
        isUploaded: false,
      });
    }
    props.setFiles([...props.files, ...result]);
  };

  const deleteImage = (file: IImage) => {
    const updatedImages = props.files.filter((f) => f.url != file.url);
    props.setFiles(updatedImages);
  };

  return (
    <div className="container" style={{ margin: "1rem 0" }}>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{
          border: "1px dotted black",
          textAlign: "center",
          padding: "1rem 0",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Slipp bildet!</p>
        ) : (
          <p>Dra inn et bilde eller klikk på "Last ned" *</p>
        )}
        <button type="button" onClick={open}>
          Last ned
        </button>
      </div>
      <div className="dropZoneImages">
        {props.files.map((file, index) => (
          <>
            <img
              alt="Legg til bildet"
              key={index}
              style={{ width: "100px" }}
              src={file.isUploaded === true ? file.url : file.content}
            ></img>
            <DeleteIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                deleteImage(file);
              }}
            />
          </>
        ))}
      </div>
    </div>
  );
}

async function getBase64(file: File): Promise<string | null | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const result = reader.result;
      resolve(result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  });
}
