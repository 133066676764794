import { Typography, Link } from "@mui/material";
import "./GuideLines.scss";

export function Guidelines() {
  return (
    <div className="guidelines">
      <Typography className="guideLinesHeader">
        Yadgaar Sweets &amp; Bakers AS retningslinjer for personvern
      </Typography>
      <Typography variant="caption" marginLeft="0.5em">
        Versjon 1.1 - 01.08.2023
      </Typography>
      <Typography className="guideLinesContent">
        <p>
          Denne personvernerklæringen forklarer når og hvorfor vi samler inn
          informasjon om deg, hvordan vi bruker den og hvordan vi holder den.
        </p>
        <p>
          Du kan navigere på nettsiden vår uten å oppgi dine personlige data.
          Hvis du imidlertid ønsker å legge til et ordre, trenger vi din
          personlige informasjon. Et ordre innebærer at du legger til produkter
          i handlekurven og velger å handle disse.
        </p>
      </Typography>
      <Typography className="guideLinesSubTitle">
        Personvernerklæring for bruk av Yadgaar Sweets &amp; Bakers AS
      </Typography>
      <Typography className="guideLinesContent">
        <p>
          Denne personvernserklæringen gjelder for Yadgaar Sweets &amp; Bakers
          AS (heretter «nettsiden», «vi» eller «oss»). Yadgaar Sweets &amp;
          Bakers AS(org.no 997862619) er ansvarlig for behandling av
          personopplysninger som samles inn om brukere (heretter «du» eller
          «deg») på Nettsiden. Du kan besøke Yadgaar Sweets &amp; Bakers AS på
          Tøyengata 27, 0578 Oslo eller kontakte de på 226 82 068.
        </p>
      </Typography>
      <Typography className="guideLinesSubTitle">
        Informasjon som innhentes
      </Typography>
      <Typography className="guideLinesContent">
        <p>
          Du kan imidlertid besøke nettstedet vårt anonymt, og det er helt
          frivillig å oppgi din personlige informasjon. Vi samler inn
          informasjon fra deg, kun når du legge til et ordre på nettstedet vårt.
          Når du bestiller på nettstedet vårt, blir du bedt om å oppgi: fornavn,
          etternavn og telefonnumer.
        </p>
        <p>
          Vi vil også sende deg en SMS-kode som engangspassord for å godkjenne
          at det er lagt til riktig telefonnummer. Det er ikke mulighet til å
          gjøre et kjøp på nettsiden, men kun legge til ordre. Dermed trenger
          ikke nettsiden kredittopplysninger eller informasjon knyttet til
          betaling.
        </p>
      </Typography>
      <Typography className="guideLinesSubTitle">
        Behandlingsgrunnlag
      </Typography>
      <Typography className="guideLinesContent">
        <p>
          Virksomheten henter inn navn og telefonnummer til den enkelt på
          følgende grunnlag:
        </p>
        <p>
          Formålet med navn og telefonnummer er todelt. For det første brukes
          navn og telefonnummer til å identifisere kunden. Vi ber om kundens
          navn og telefonnummer for å identifisere at vi kommuniserer med en
          reell person. Virksomheten har kommet frem til at
          behandlingsgrunnlaget er «samtykke» jf. personvernforordningens
          artikkel 6 nr. 1, bokstav a). Det betyr at virksomheten må respektere
          den enkeltes ønsker hvis samtykket trekkes tilbake. Vi kan ikke bytte
          behandlingsgrunnlaget hvis samtykke trekkes tilbake eller samtykke
          ikke var gyldig innhentet.
        </p>
        <p>
          For det andre brukes navn og telefonnummer til å registrere kunden,
          slik at det er mulig å legge til en ordre på virksomhetens hjemmeside.
          Virksomheten har kommet frem til at behandlingsgrunnlaget er
          «nødvendig for avtale» jf. personvernforordningens artikkel 6 nr. 1,
          bokstav b).
        </p>
      </Typography>
      <Typography className="guideLinesSubTitle">
        Formålet med å hente informasjon
      </Typography>
      <Typography className="guideLinesContent">
        <p>
          Registrering av personopplysninger på denne nettsiden er helt
          frivillig. Vårt formål med å innhente informasjon om navn og
          telefonnummer er først og fremst for å identifisere deg. Vi gjør dette
          også for at du skal slippe å registrere deg hver gang du legger til
          ordre hos oss.
        </p>
        <p>
          Telefonnummer du oppgir, kan brukes til å sende deg informasjon og
          oppdateringer knyttet til din bestilling. Informasjonen du oppgir kan
          også brukes til å kontakte deg, dersom du ikke møter opp i butikk
          etter å ha lagt inn ordre.
        </p>
      </Typography>
      <Typography className="guideLinesSubTitle">
        Lagring og sletting av opplysninger / informasjon
      </Typography>
      <Typography className="guideLinesContent">
        <p>
          Den innhentede informasjonen vil være tilgjengelig for vår
          administrative bruker og lagres på Amazon Web Services sine servere.
          Som bruker av nettsiden kan du kreve innsyn i personopplysningene som
          innhentes om deg, i tillegg til at du også kan kreve retting, sletting
          og begrensninger i behandlingen av personopplysninger i henhold til
          personopplysningsloven KAPITTEL III Den registrertes rettigheter. Du
          har også rett til å få korrigert eventuelle feil i dine
          personopplysninger. Ved godkjenning av personvernet vil dine
          opplysninger bli oppbevart hos oss, til du gir oss beskjed om at du
          ikke lenger ønsker dette.
        </p>
        <p>
          Den registrerte skal ha rett til å få personopplysninger om seg selv
          slettet av den behandlingsansvarlige uten ugrunnet opphold, jf.
          personopplysningsloven § 17, nr. 1. Tilbaketrekking vil ikke påvirke
          lovligheten av behandlingen basert på samtykket før tilbaketrekkingen.
          Du kan når som helst trekke tilbake samtykket ditt og få den
          innhentede informasjonen slettet ved å &nbsp;
          <Link href="/slett" underline="hover">
            trykke deg inn her.
          </Link>
        </p>
      </Typography>
      <Typography className="guideLinesSubTitle">
        Utlevering av informasjon
      </Typography>
      <Typography className="guideLinesContent">
        <p>
          Vi behandler all informasjon som vi innhenter som konfidensiell og vil
          på ingen måte dele den med en tredjepart uten ditt samtykke.
          Informasjon som du oppgir på nettsiden er hovedsakelig tilgjengelig
          for vårt IT-team og kundeservice.
        </p>
      </Typography>
      <Typography className="guideLinesSubTitle">
        Angrerett og refusjon
      </Typography>
      <Typography className="guideLinesContent">
        <p>
          I henhold til angrerettloven § 1, er loven gjeldende ved salg av varer
          og tjenester til forbruker. På denne nettsiden vil ikke du ha
          muligheten til å kjøpe et produkt, men kun legge til et orde. Kjøpet
          blir gjort når du kommer til butikken.
        </p>
        <p>
          Du vil ha muligheten til å avbestille orde du har lagt inn. Dette kan
          kun gjøres ved å kontakte butikken direkte på deres telefonnummer (+47
          22 68 20 68), med henvisning til bestillingsnummeret du mottok på SMS.
        </p>
      </Typography>
      <Typography className="guideLinesSubTitle">
        Endringer i vår personvernerklæring
      </Typography>
      <Typography className="guideLinesContent">
        <p>
          Vi forbeholder oss retten til å endre personvernserklæringen. Hvis vi
          bestemmer oss for å endre personvernreglene våre, vil vi legge ut
          disse endringene på denne siden, og/eller oppdatere endringsdatoen for
          personvernreglene. Du er selv ansvarlig for å sørge for at du til
          enhver tid godtar den gjeldende personvernserklæringen.
        </p>
        <p>
          Dersom du ikke skulle godta denne må du umiddelbart slutte å handle
          hos oss og &nbsp;
          <Link href="/slett" underline="hover">
            trykke deg inn her
          </Link>
          &nbsp;for å få informasjonen din slettet hos oss. Dersom du mener at
          vi ikke har overholdt dine rettigheter i henhold til
          personopplysningsloven har du rett til å klage til datatilsynet.
        </p>
      </Typography>
      <Typography className="guideLinesSubTitle">Kontaktinformasjon</Typography>
      <Typography className="guideLinesContent">
        <p style={{ marginBottom: "2em" }}>
          Hvis det er spørsmål angående denne personvernreglene eller
          behandlingen av personopplysninger, kan du kontakte oss ved å sende
          e-post til:{" "}
          <a href="mailto:web.for.you@hotmail.com">web.for.you@hotmail.com</a>
        </p>
      </Typography>
    </div>
  );
}
