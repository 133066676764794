import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import ProtectedRoute from "./Helpers/ProtectedRoute";
import { XlContainerLayout } from "./Layouts/XlContainerLayout";
import { GetSessionStatus } from "./Logic/AccountService";
import { MainPage } from "./MainPage";
import { setCategories } from "./Store/CategoryStore";
import { About } from "./View/About/About";
import { AdminLayout } from "./Layouts/AdminLayout";
import { Login } from "./View/Admin/Login";
import { Orders } from "./View/Admin/Order/Orders";
import { ProductTable } from "./View/Admin/Product/ProductTable";
import { ProductForm } from "./View/Admin/ProductForm";
import { Bugs } from "./View/Bugs";
import { CategoryContent } from "./View/Category/CategoryContent";
import { CheckOut } from "./View/CheckOut/CheckOut";
import { DeleteUser } from "./View/GuideLines/DeleteUser";
import { Guidelines } from "./View/GuideLines/GuideLines";
import { MainLayout } from "./Layouts/MainLayout";
import { ICategory } from "./Models/Model";
import { getCategories } from "./Logic/ProductService";
import { NothingHere } from "./Helpers/NothingHere";
import { CategoryForm } from "./View/Admin/CategoryForm";
import { PickAndMixProducts } from "./View/PickAndMix/PickAndMixProducts";

function App() {
  const dispatch = useDispatch();
  const SetupPageData = async () => {
    const categories = await getCategories();
    dispatch(setCategories(categories as ICategory[]));
  };

  const [buttonLoader, setButtonLoader] = useState(false);
  //TODO : HER MÅ DET HÅNDTERES.

  useEffect(() => {
    GetSessionStatus();
    SetupPageData();
  });

  return (
    <Box display="relative" margin={0} width="100%">
      <Routes>
        <Route
          element={
            <MainLayout
              buttonLoader={buttonLoader}
              showNavBarFooterMobile={true}
            />
          }
        >
          <Route
            index
            element={
              <MainPage
                buttonLoader={buttonLoader}
                setButtonLoader={setButtonLoader}
              />
            }
          />
          <Route element={<XlContainerLayout />}>
            <Route
              path="Kategori/:categoryName"
              element={<CategoryContent />}
            ></Route>
            <Route
              path={"/Kategori/Mithai/PlukkMiks"}
              element={<PickAndMixProducts />}
            />
            <Route path="Omoss" element={<About />}></Route>
            <Route path="Login" element={<Login />}></Route>
            <Route path="Personvern" element={<Guidelines />}></Route>
            <Route path="Slett" element={<DeleteUser />}></Route>
            <Route path="NothingHere" element={<NothingHere />}></Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Route>
        <Route
          element={
            <MainLayout
              buttonLoader={buttonLoader}
              showNavBarFooterMobile={false}
            />
          }
        >
          <Route element={<XlContainerLayout />}>
            <Route path="Handlekurv" element={<CheckOut />}></Route>
          </Route>
        </Route>

        <Route
          path="Admin"
          element={<ProtectedRoute component={<AdminLayout />} />}
        >
          <Route index element={<Orders />} />
          <Route path="Bugs" element={<Bugs />} />
          <Route path="NyttProdukt" element={<ProductForm />} />
          <Route path="Kategorier" element={<CategoryForm />} />
          <Route path="Produkter" element={<ProductTable />} />
        </Route>
      </Routes>
    </Box>
  );
}

export default App;
