export function Bugs() {
  return (
    <div>
      <ul>
        <h1> MÅ GJØRES FØR LANSERING (KODEOPPGAVER): </h1>
        <li></li>

        <h2>ANDRE TING FØR LANSERING</h2>
        <li>
        </li>
      </ul>

      <ul>
        <h2> Ønsker fra kunde: </h2>
        <li>Logo øverst på ordre på kvittering</li>
        <li>Mail</li>
        <li>0.25 0.5 0.75 1 på kg</li>
        <li>Betalingsløsning</li>
      </ul>


      <ul>
        <h2> KAN VENTES MED: </h2>
        <li>Kategori - kan ikke legge til ny, velge rekkefølge osv</li>
        <li>Slette alarms fra Amazon Web Service</li>
        <li>Inputfelt på +-knapp for antall pr produkt</li>
        <li>Endre kategori fra Admin</li>
        <li>Bedre SMS melding til kunden på mottatt bestilling (Ha med varene f.eks.)</li>
        <li>Fikse at Admin kan kun legge til 1 bildet, ved ny/endre produkt</li>
        <li>Kun tillat med jpg fil, når man legger til/redigerer bildet.</li>
        <li>
          Validering på felter, at de blir fylt ut riktig og feilhåndtering i
          Admin
        </li>
        <li>AWS mail om Lambda function using Node.js 14 runtime - bytte til Node.js 18 før 27.november</li>
        <li>Bruke React Hooks riktig over hele nettsiden</li>
        <li>Se TODO</li>
        <li>Admin - mobil fix (responsiv)</li>
        <li>Enviroment variable</li>
        <li>Ventetid etter antall ordre</li>
        <li>Responsiv på tablet</li>
        <li>SEO</li>
        <li>
          Ikke la Navbar flytte på seg seg, når man legger til flere produkter
          og prisen øker
        </li>
        <li>Admin - velger rekkefølge av visning av produkter</li>
        <li>Slette et produkt</li>
        <li>React custom hooks på input Admin og validering</li>
        <li>Gi riktig feilmelding til brukeren og utviklerne</li>
        <li>Skal vi ha med "resent code" eller ikke. Få ny passord</li>
        <li>Feil på console nettsiden</li>
        <li>Legg til betalingsløsning</li>
        <li>Mer styling</li>
        <li>Opphavsrett på bildene</li>
      </ul>
    </div>
  );
}

/*    <li>
         kostnader - Bildet: 400, Adobe: 700, AWS: 550(250 zuheb, 300 furk), Printer:
1200, Facebook: 500(Zuheb) + Ny printer: 650 kr(Sajan) = 4000 kr
        </li >*/ 