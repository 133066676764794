import { Typography } from "@mui/material";
import { Units } from "../Models/Enum";
import { IProduct, IShoppingCart } from "../Models/Model";

export function GetTotalPrice(shoppingCart: any) {
  let sum = 0;

  shoppingCart.forEach((cartItem: IShoppingCart) => {
    sum += GetProductPrice(cartItem.product, cartItem.quantity);
  });

  return sum;
}

export function GetProductPrice(product: IProduct, quantity: number) {
  return (product.isOnSale ? product.salePrice : product.price) * quantity;
}

export function GetSalePercent(price: number, salePrice: number) {
  return (((price - salePrice) / price) * 100).toFixed(0);
}

export function GetProductQuantity(shoppingCart: any) {
  if (shoppingCart === undefined) {
    return 0;
  }

  return shoppingCart.length;
}

export function ShowOldPrice(product: IProduct) {
  if (product.isOnSale) {
    return (
      <Typography
        variant="caption"
        color="text.secondary"
        component="div"
        sx={{ textDecoration: "line-through" }}
      >
        Før {product.price},- {UnitText(product.unit)}
        ,-
      </Typography>
    );
  }
  return <></>;
}

export function GetPriceText(product: IProduct) {
  const unitText = UnitText(product.unit);
  if (product.isOnSale) {
    return (
      <div>
        <Typography variant="body1" sx={{ color: "red" }}>
          {product.salePrice},- {unitText}
        </Typography>
      </div>
    );
  }
  return (
    <Typography variant="body1" color="text.secondary">
      {product.price},- {unitText}
    </Typography>
  );
}

export function UnitText(unit: string): string {
  if (unit !== Units.stk) {
    return "per " + unit;
  }

  return "";
}
