import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export function NothingHere() {
  return (
    <Box>
      <h1>Det er ingenting på denne siden!</h1>
      <Link to="/">Returner til hjemsiden</Link>
    </Box>
  );
}
