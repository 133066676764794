export enum Units {
  stk = "stk",
  kg = "kg",
}

export enum OrderStatus {
  Nytt = "Nytt",
  Ferdig = "Ferdig",
  Avbrutt = "Avbrutt",
}

export enum PAGES {
  PHONENR = 1,
  VERIFY = 2,
  INFO = 3,
  COMPLETED = 4,
}
