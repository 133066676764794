import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { IAttribute, IProduct, IShoppingCart } from "../../Models/Model";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import "./ProductInfoDialog.scss";
import {
  GetProductPrice,
  GetSalePercent,
} from "../../Logic/ProductsCalculation";
import { useDispatch, useSelector } from "react-redux";
import { setProductShoppingCart } from "../../Store/ProductsStore";
import { useState, useEffect } from "react";
import { GetChip } from "../../Helpers/CustomChip";
import { QuantityCounter } from "../Inputs/QuantityCounter";
import CloseIcon from "@mui/icons-material/Close";

export interface IProductInfoDialogProps {
  product: IProduct;
  isOpenProductInfoDialog: boolean;
  setOpenProductInfoDialog: (open: boolean) => void;
}

export default function ProductInfoDialog(props: IProductInfoDialogProps) {
  const { product, setOpenProductInfoDialog, isOpenProductInfoDialog } = props;
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("lg"));

  const shoppingCartItems = useSelector(
    (state: any) => state.ProductStore.shoppingCart as IShoppingCart[]
  );
  var shoppingCartItem = shoppingCartItems.find(
    (item) => item.product.productId === product.productId
  );
  var isInShoppingCart = shoppingCartItem !== undefined;
  var shoppingCartQuantity =
    shoppingCartItem !== undefined ? shoppingCartItem.quantity : 0;

  const [quantity, setQuantity] = useState(
    shoppingCartQuantity > 0 ? shoppingCartQuantity : 1
  );

  useEffect(() => {
    if (isInShoppingCart) {
      setQuantity(shoppingCartQuantity);
    }
  }, [shoppingCartQuantity]);

  const updateShoppingCartQuantity = (quantity: any) => {
    if (isInShoppingCart) {
      dispatch(
        setProductShoppingCart({
          product: product,
          quantity: quantity,
          isMixMithaiBox: false,
        })
      );
    } else {
      if (quantity > 0) {
        setQuantity(quantity);
      }
    }
  };

  const addProductToShoppingCart = () => {
    dispatch(
      setProductShoppingCart({
        product: product,
        quantity: quantity,
        isMixMithaiBox: false,
      })
    );
  };

  const handleDialogClose = () => {
    setOpenProductInfoDialog(false);
  };

  return (
    <Dialog
      open={isOpenProductInfoDialog}
      onClose={handleDialogClose}
      maxWidth={isSmallScreen ? "sm" : "lg"}
      fullWidth
    >
      {GetChip(product, false)}

      <DialogContent className="productInfoDialogWrap">
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box className="productInfoDialogContent">
          <Box>
            <img
              src={product.images[0] ? product.images[0].url : "/noImage.png"}
              alt={product.name}
              className="productInfoDialogImg"
            />
          </Box>

          <Box className="productInfoDialogInformationWrap">
            <Typography className="productInfoDialogName">
              {product.name}
            </Typography>
            <Typography className="productInfoDialogDescription">
              {product.description}
            </Typography>
            {product.attributes &&
              product.attributes.map((attribute: IAttribute, i: number) => (
                <div key={i}>
                  <Typography className="productInfoDialogAttributeTitle">
                    {attribute.key}
                  </Typography>
                  <Typography className="productInfoDialogAttributeDescription">
                    {attribute.value}
                  </Typography>
                </div>
              ))}
          </Box>

          <Box>
            <Box className="productInfoDialogPriceWrap withMarginTop">
              <Typography className="productInfoDialogPricesTitle">
                Pris
              </Typography>
              <Typography
                className={`productInfoDialogPricesValue ${product.isOnSale ? "textLineThrough" : ""
                  }`}
              >
                {product.price} Kr
              </Typography>
              <Typography className="productInfoDialogUnit">
                /{product.unit}
              </Typography>
            </Box>
            {product.isOnSale && (
              <Box className="productInfoDialogPriceWrap">
                <Typography className="productInfoDialogPricesTitle">
                  Salgspris ({GetSalePercent(product.price, product.salePrice)}
                  %)
                </Typography>
                <Typography className="productInfoDialogPricesValue">
                  {product.salePrice} Kr
                </Typography>
                <Typography className="productInfoDialogUnit">
                  /{product.unit}
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            className="productInfoDialogBtn quantity"
            sx={{ opacity: product.isSoldOut ? "50%" : null }}
          >
            <Typography className="quantityBtnText">Antall</Typography>
            <QuantityCounter
              setValue={updateShoppingCartQuantity}
              unit={product.unit}
              quantity={quantity}
            />
          </Box>

          <Box
            component="button"
            className="productInfoDialogBtn addToCart"
            onClick={() => {
              {
                !isInShoppingCart && addProductToShoppingCart();
                handleDialogClose();
              }
            }}
            disabled={product.isSoldOut}
            sx={{
              cursor:
                isInShoppingCart || product.isSoldOut ? "default" : "pointer",
              opacity: product.isSoldOut ? "50%" : null,
            }}
          >
            <Typography className="addToCartBtnText">
              {isInShoppingCart ? "Lagt til" : "Legg til"}
            </Typography>
            <Typography className="addToCartBtnText">
              {GetProductPrice(product, quantity)} kr ({quantity} {product.unit}
              )
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
