import { Box } from "@mui/material";
import { IProduct } from "../Models/Model";
import { GetSalePercent } from "../Logic/ProductsCalculation";

export const GetChip = (product: IProduct, isMixMithai: boolean) => {
  if (product.isSoldOut) {
    return CustomChip("lightgray", "Utsolgt");
  } else if (product.isOnSale && !isMixMithai) {
    return CustomChip(
      "#56d674",
      GetSalePercent(product.price, product.salePrice).toString() + "%",
    );
  } else if (product.isBestSeller) {
    return CustomChip("purple", "Bestselger");
  } else {
    return null;
  }
};

const CustomChip = (color: string, text: string) => {
  return (
    <>
      <Box
        component="span"
        sx={{ background: color, zIndex: "1" }}
        className="chip"
      >
        {text}
      </Box>
    </>
  );
};
