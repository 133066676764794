import React from "react";

export function inputTextHandler(inputValue: string) {
  const isValid = inputValue.trim().length !== 0;
  if (isValid) {
    return true;
  } else {
    return false;
  }
}
export function inputNumberHandler(event: React.ChangeEvent<HTMLInputElement>) {
  let inputVerdi = event.target.value;
  const isWhiteSpace = (inputVerdi || "").trim().length === 0;
  const isValid = !isWhiteSpace;
  const regex = /^[0-9\b]+$/;
  if (isValid && regex.test(inputVerdi)) {
    return inputVerdi;
  } else {
    inputVerdi = "";
    return inputVerdi;
  }
}
