import {
  Typography,
  Button,
  Box,
  TextField,
  Link,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { verifyCustomer } from "../../../Logic/CustomerService";
import { ICustomer } from "../../../Models/Model";
import { PAGES } from "../../../Models/Enum";
import "../CheckOut";
import "./Stepper.scss";
import { useState } from "react";

interface props {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  customer: ICustomer;
  setCustomer: React.Dispatch<React.SetStateAction<ICustomer>>;
  setLoading: any;
  isLoading: boolean;
}

export function VerifyPhoneForm(props: props) {
  const { customer, setPage, setCustomer } = props;
  const [oneTimePassword, setOneTimePassword] = useState("");
  const [oneTimePasswordValid, setOneTimePasswordValid] = useState(true);
  const [GDPR, setGDPR] = useState(false);
  const [GDPRValid, setGDPRValid] = useState(true);

  const handleGDPR = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGDPR(event.target.checked);
    if (event.target.checked) {
      setGDPRValid(true);
    }
  };

  function handleOneTimePasswordValidation(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setOneTimePassword(e.target.value);
    setOneTimePasswordValid(OneTimePasswordIsValid(e.target.value));
  }

  function OneTimePasswordIsValid(password: string) {
    if (password.trim().length == 4) {
      return /^\d{4}$/.test(password.trim());
    }

    return false;
  }

  async function VerifyCustomer() {
    if (!GDPR) {
      setGDPRValid(false);
      return;
    }

    if (!OneTimePasswordIsValid(oneTimePassword)) {
      setOneTimePasswordValid(false);
      return;
    }

    props.setLoading(true);
    var response = await verifyCustomer(customer, oneTimePassword);

    if (response) {
      setCustomer(response as ICustomer);
      setPage(PAGES.INFO);
    } else {
      setOneTimePasswordValid(false);
      console.log("ikke verifisert");
    }

    props.setLoading(false);
  }

  return (
    <Box>
      <Typography className="verifyFormText" gutterBottom>
        Bekreftelseskode sendt til {customer.phoneNumber}
      </Typography>
      <TextField
        tabIndex={1}
        name="SMS kode"
        label="SMS kode"
        className="verifyFormField"
        variant="outlined"
        fullWidth
        value={oneTimePassword}
        onChange={(e) => handleOneTimePasswordValidation(e)}
        error={!oneTimePasswordValid}
        required={true}
        helperText={
          !oneTimePasswordValid
            ? "Vennligst skriv inn riktig engangspassord"
            : ""
        }
        FormHelperTextProps={{
          style: {
            backgroundColor: "#fff7f9",
            margin: 0,
            padding: 10,
          },
        }}
      />

      <Button
        variant="contained"
        className="verifyFormBtn"
        fullWidth
        onClick={async () => await VerifyCustomer()}
      >
        Bekreft
      </Button>
      <Box display="flex" marginTop="2rem" alignContent="center">
        <Checkbox
          required
          checked={GDPR}
          onChange={handleGDPR}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "2rem" },
          }}
        />
        <Typography variant="caption" className="GDPR">
          Godkjenner du at Yadgaar lagrer ditt navn og telefonnummer for å sende
          deg informasjon om dine ordre for fremtiden. Ved å godta aksepterer du
          vår&nbsp;
          <Link href="/personvern" underline="hover">
            personvern
          </Link>
          *
        </Typography>
      </Box>
      {!GDPRValid && (
        <FormHelperText className="GDPRHelperText">
          Du må godkjenne for å gå videre. *
        </FormHelperText>
      )}
    </Box>
  );
}
