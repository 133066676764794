import { Container, Typography } from "@mui/material";
import { PulseLoader } from "react-spinners";

export default function Loader() {
  return (
    <Container disableGutters className="Loader">
      <PulseLoader color={"#393939"} size={"1rem"} />
      <Typography variant="h5">Laster ned...</Typography>
    </Container>
  );
}
