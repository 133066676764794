import { useSelector } from "react-redux";
import Userpool from "../Logic/UserPool";
import { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

interface props {
  component: JSX.Element;
}
function ProtectedRoute(props: props) {
  const status = useSelector((state: any) => state.AdminStore.status);

  if (status) {
    return props.component;
  }

  return <Navigate to="/Login" />;
}

export default ProtectedRoute;
