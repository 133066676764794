import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../Helpers/ScrollToTop";
import { Footer } from "../View/Footer/Footer";
import { NavBar } from "../View/Navigation/NavBar";

interface props {
  showNavBarFooterMobile: boolean;
  buttonLoader: boolean;
}

export function MainLayout(props: props) {
  return (
    <ScrollToTop>
      <NavBar />
      <Box sx={{ minHeight: "100vh", marginBottom: "5rem" }}>
        <Outlet />
      </Box>
      {props.showNavBarFooterMobile && !props.buttonLoader && <Footer />}
    </ScrollToTop>
  );
}
