import CheckIcon from "@mui/icons-material/Check";
import { Badge } from "@mui/material";
import "./Stepper.scss";

export const Stepper = (stepNo: number) => {
  const checkIcon = <CheckIcon fontSize="small" />;
  const steps = ["Mobilnummer", "Verfiser", "Bekreft"];

  return (
    <div className="stepper-wrapper">
      {steps.map((step, i) => (
        <div className={`stepper-item ${stepNo === i + 1 && "active"}`} key={i}>
          {i + 1 < stepNo ? (
            <Badge
              badgeContent={checkIcon}
              className="done"
              sx={{
                "& .MuiBadge-badge": {
                  border: "1px solid #56d674 ",
                  borderRadius: "50%" as "borderRadius",
                  backgroundColor: "#56d674 ",
                  color: "white ",
                  width: "25px ",
                  height: "25px" as "height",
                  marginTop: "5px ",
                  zIndex: "5",
                },
              }}
            >
              <div className="step-counter">{i + 1}</div>
            </Badge>
          ) : (
            <div className="step-counter">{i + 1}</div>
          )}
          <div className="step-name">{step}</div>
        </div>
      ))}
    </div>
  );
};
