import { useSelector } from "react-redux";
import { Divider, IconButton, Snackbar } from "@mui/material";
import React from "react";
import { EmptyCheckOut } from "./EmptyCheckOut";
import { OrderLines } from "./Components/OrderLines";
import { IOrder } from "../../Models/Model";
import { storeIsClosing } from "../../Logic/ClosingHours";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Unstable_Grid2";
import "../../App.scss";
import { PAGES } from "../../Models/Enum";
import { StepWrapper } from "./Steps/StepWrapper";
import { SuccessfulCheckOut } from "./Steps/ SuccessfulCheckOut";

export function CheckOut() {
  const shoppingCart = useSelector(
    (state: any) => state.ProductStore.shoppingCart,
  );
  const handleClose = () => {
    setIsStoreClosing(false);
  };

  const [page, setPage] = React.useState<PAGES>(PAGES.PHONENR);
  const [order, setOrder] = React.useState<IOrder>();
  const orderIsCompleted = page === PAGES.COMPLETED;
  const currentShoppingCart =
    orderIsCompleted && order !== undefined ? order.shoppingCart : shoppingCart;
  const [isStoreClosing, setIsStoreClosing] = React.useState(storeIsClosing);

  if (!shoppingCart || (shoppingCart.length == 0 && page !== PAGES.COMPLETED)) {
    return <EmptyCheckOut />;
  }
  return (
    <>
      {page === PAGES.COMPLETED && order ? (
        <>
          <StepWrapper page={page} setPage={setPage} setOrder={setOrder} />
          <SuccessfulCheckOut order={order}></SuccessfulCheckOut>
        </>
      ) : (
        <Grid
          container
          spacing={{ xs: 0, lg: 2 }}
          justifyContent="center"
          direction={{ xs: "column-reverse", lg: "row" }}
        >
          <Grid xs={12} lg={5} paddingTop="2em">
            <StepWrapper page={page} setPage={setPage} setOrder={setOrder} />
          </Grid>

          <Grid display={{ xs: "none", lg: "grid" }}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ minHeight: "100vh" }}
            />
          </Grid>

          <Grid lg={6} display={{ xs: "none", lg: "grid" }} paddingTop="2.5em">
            <OrderLines
              shoppingCart={currentShoppingCart}
              page={page}
              isSideDrawer={false}
            />
          </Grid>
        </Grid>
      )}

      {isStoreClosing && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={isStoreClosing}
          message={isStoreClosing ? "Nettbutikken stenger 20:45." : null}
          action={
            <IconButton
              size="small"
              aria-label="closeInfoBtn"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      )}
    </>
  );
}
