import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGES } from "../../Models/Enum";
import { IShoppingCart } from "../../Models/Model";
import { OrderLines } from "../CheckOut/Components/OrderLines";
import { closeSideDrawer } from "../../Store/ProductsStore";
import "./SideDrawer.scss";

export default function SideDrawer() {
  const dispatch = useDispatch();
  const open = useSelector((state: any) => state.ProductStore.sideDrawerIsOpen);

  const shoppingCart = useSelector(
    (state: any) => state.ProductStore.shoppingCart
  ) as IShoppingCart[];

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      dispatch(closeSideDrawer());
    };

  const sidePanel = () => (
    <Box className="sideDrawer" role="presentation">
      <Box className="sideDrawerTop">
        <Typography className="sideDrawerTopHeader">Handlekurv</Typography>
        {shoppingCart.length > 0 && (
          <Typography className="sideDrawerShoppingCartQuantity">
            {shoppingCart.length} produkt{shoppingCart.length > 1 && "er"}
          </Typography>
        )}
      </Box>

      {shoppingCart.length == 0 ? (
        <Box className="emptyShoppingCart">
          <Typography className="emptyShoppingCartText">
            Handlekurven er tom
          </Typography>
          <Button
            className="orderLineBtn continueShoppingBtn"
            variant="outlined"
            onClick={() => {
              dispatch(closeSideDrawer());
              toggleDrawer(false);
            }}
          >
            Fortsett å handle
          </Button>
        </Box>
      ) : (
        <>
          <OrderLines
            shoppingCart={shoppingCart}
            page={PAGES.PHONENR}
            isSideDrawer={true}
          ></OrderLines>
        </>
      )}
    </Box>
  );

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          elevation: 16,
          sx: {
            width: { xs: "100%", lg: "800px" },
          },
        }}
      >
        {sidePanel()}
      </Drawer>
    </React.Fragment>
  );
}
