import { TextField } from "@mui/material";
import { useState } from "react";
import { inputTextHandler } from "./Validation";

interface TextFieldStringInterface {
  label: string;
  value: string;
  setValue: (value: React.SetStateAction<string>) => void;
  required: boolean;
}

export default function TextFieldString(props: TextFieldStringInterface) {
  const [error, setError] = useState(false);
  const helperText = error ? props.label + " må fylles ut!" : "";

  return (
    <TextField
      label={props.label}
      variant="outlined"
      size="small"
      margin="normal"
      value={props.value}
      error={error}
      required={props.required}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setError(props.required && !inputTextHandler(props.value));
        props.setValue(event.target.value);
      }}
      helperText={helperText}
    />
  );
}
