import { CognitoUserSession } from "amazon-cognito-identity-js";
import store from "..";
import { setStatus } from "../Store/AdminStore";
import UserPool from "./UserPool";

export function GetSessionStatus() {
  const user = UserPool.getCurrentUser();

  if (user) {
    user.getSession((err: any, session: any) => {
      if (err) {
        console.log("ingen logget inn");
        store.dispatch(setStatus(false));
      } else {
        console.log(session);
        store.dispatch(setStatus(true));
      }
    });
  }
}

export const getIdToken = () => {
  let token = "";
  const user = UserPool.getCurrentUser();

  if (user) {
    user.getSession((err: any, session: CognitoUserSession) => {
      if (err) {
        console.warn("ingen logget inn");
      } else {
        token = session.getIdToken().getJwtToken();
      }
    });
  } else {
    window.location.pathname = "login";
  }
  return token;
};

export function LogOut() {
  const user = UserPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
  store.dispatch(setStatus(false));
}
